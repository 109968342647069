import axios from 'axios';
import CryptoService from './cryptoService';
import { apiRoutes } from '../constants/apiRoutes';

export default class ApiService {
  static ROOT_URL = apiRoutes.baseUrl;

  static serverURL = '';
  static username = '';
  static password = '';
  static instance = '';

  static getCredentialsFromLocalStorage() {
    let currentLocalStorage = JSON.parse(window.localStorage.getItem('currentUser')) || '';
    this.serverURL = currentLocalStorage['serverURL'] || '';
    this.username = currentLocalStorage['username'] || '';
    this.password = currentLocalStorage['password'] ?
        CryptoService.decryptString(currentLocalStorage['password']) :
        CryptoService.decryptString('')
  }

  static setCredentials(serverURL, username, password) {
    this.serverURL = serverURL;
    this.username = username;
    this.password = password;
  }

  static async performRequest(type, url, data = null, credentials = null) {
    // If credentials are provided , login with provided credentials. Otherwise, use credentials from local storage
    if (credentials !== null)
      this.setCredentials(
        credentials.serverURL,
        credentials.username,
        credentials.password
      );
    else this.getCredentialsFromLocalStorage();

    const apiSettings = {
      baseURL: `${this.serverURL}/apis/pbx`,
      auth: {
        username: this.username,
        password: this.password
      },
      validateStatus: status => status >= 200 && status < 300
    };

    this.instance = axios.create(apiSettings);

    const requestURL = this.serverURL + this.ROOT_URL + url;
    let response;

    try {
      switch (type) {
        case 'GET':
          response = await this.instance.get(requestURL, data);
          break;
        case 'POST':
          response = await this.instance.post(requestURL, data);
          break;
        default:
          return null;
      }
    } catch (e) {
      throw e;
    }
    return response;
  }
}
