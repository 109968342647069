// @flow
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import userReducer from './userReducer';
import userPhonesReducer from './phonesReducer';
import authenticationReducer from './authenticationReducer';
import userSwitches from './userSwitchesReducer';
import settings from './settingsReducer';
import statsReducer from './acdStatsReducer';
import connectorsCRM from './connectsReducer';

export default function createRootReducer(history: History) {
  return combineReducers<{}, *>({
    router: connectRouter(history),
    userSwitches: userSwitches,
    authentication: authenticationReducer,
    settings: settings,
    user: userReducer,
    phones: userPhonesReducer,
    stats: statsReducer,
    connectors: connectorsCRM
  });
}
