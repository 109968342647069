// @flow
import React from 'react';
import { NavLink } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Icon,
  Header,
  Divider,
  Button,
  Grid, Table
} from 'semantic-ui-react';

import acdStatsActions from '../../actions/acdStatsActions';

class ACDStatsPage extends React.Component {
  componentDidMount() {
    this.props.acdStatsActions.fetchStats();
  }

  render() {
    return (
        <div className="ui container">
          <br/>
          <Grid textAlign="center" columns={3}>
            <Grid.Row>
              <Grid.Column>
                <NavLink
                    exact
                    activeClassName="active orange"
                    to="/settings"
                    className="item"
                >
                  <Button icon labelPosition="left">
                    <Icon name="left arrow"/>
                    Back
                  </Button>
                </NavLink>
              </Grid.Column>
              <Grid.Column>
                <Header as="h2" icon="chart bar" content="ACD Stats"/>
              </Grid.Column>
              <Grid.Column/>
            </Grid.Row>
          </Grid>

          <Divider/>

          {this.props.acd_tool ? (this.props.stats.map(queue => {
                return (
                    <div key={queue.uuid}>
                      <Table columns={6} unstackable>
                        <Table.Header fullWidth>
                          <Table.Row textAlign="center"><Table.HeaderCell
                              colSpan='6'>{queue.name}</Table.HeaderCell></Table.Row>
                        </Table.Header>

                        <Table.Body>
                          <Table.Row verticalAlign="top" textAlign="center">
                            <Table.Cell>
                              <Header>
                                <Header.Content>
                                  {queue.calls_waiting}
                                  <Header.Subheader>Waiting calls</Header.Subheader>
                                </Header.Content>
                              </Header>
                            </Table.Cell>
                            <Table.Cell>
                              <Header>
                                <Header.Content>
                                  {queue.longest_hold_time}
                                  <Header.Subheader>Longest hold time</Header.Subheader>
                                </Header.Content>
                              </Header>
                            </Table.Cell>
                            <Table.Cell>
                              <Header>
                                <Header.Content>
                                  {queue.answered}
                                  <Header.Subheader>Answered</Header.Subheader>
                                </Header.Content>
                              </Header>
                            </Table.Cell>
                            <Table.Cell>
                              <Header>
                                <Header.Content>
                                  {queue.aborted}
                                  <Header.Subheader>Aborted</Header.Subheader>
                                </Header.Content>
                              </Header>
                            </Table.Cell>
                            <Table.Cell>
                              <Header>
                                <Header.Content>
                                  {queue.free_agents}
                                  <Header.Subheader>Free agents</Header.Subheader>
                                </Header.Content>
                              </Header>
                            </Table.Cell>
                            <Table.Cell>
                              <Header>
                                <Header.Content>
                                  {queue.all_agents}
                                  <Header.Subheader>All agents</Header.Subheader>
                                </Header.Content>
                              </Header>
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </div>
                );
              }))
              :
              <div className="ui padded basic segment">
                <h2 className="ui header">
                  <i className="info big icon"/>
                  <div className="content">
                    Please contact your administrator in order to gain access to ACD stats!
                  </div>
                </h2>
              </div>}
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    stats: state.stats.queues,
    acd_tool: state.settings.acd_tool
  };
};

const mapDispatchToProps = dispatch => {
  return {
    acdStatsActions: bindActionCreators(acdStatsActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ACDStatsPage);
