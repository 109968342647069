import axios from 'axios';
import CryptoService from '../services/cryptoService';
import { sendNotification } from './notificationHelper';
import {isValidUrl} from "../services/validUrl";
import path from 'path';

const iconPath = path.join(__dirname, 'resources/ucmIconMac.png');

const redirectToConnectors = (main = false, connector, caller) => {
  let url = '';
  let result;

  if (main) {
    let templates = window.localStorage.getItem('crm-config') ? JSON.parse(window.localStorage.getItem('crm-config'))['templates'] : [];
    [result] = templates.filter(item => item.key === connector);
  }

  if (!main) {
    result = connector;
  }

  if (result.value.substr(-1) === '/') {
    url = `${result.value}${caller.number}`;
  } else {
    url = `${result.value}&q=${caller.number}`;
  }

  if(isValidUrl(url)){
    window.open(url, '_blank');
  } else {
    new Notification('UC Manager', {
      icon: iconPath,
      body: `Url [${url}] is not valid`,
      dir: 'auto',
      requireInteraction: true
    });
  }
};

const redirectToMedicom = data => {
  const pbx_uuid = window.localStorage.getItem('pbx_uuid') ? window.localStorage.getItem('pbx_uuid') : '';
  let currentLocalStorage = window.localStorage.getItem('crm-config') ? JSON.parse(window.localStorage.getItem('crm-config'))['medicom'] : '';
  const serverURL =  currentLocalStorage['serverURL'] || '';
  const gpCrmUserId = currentLocalStorage['gpCrmUserId'] || '';
  let currentLocalStorage4User = JSON.parse(window.localStorage.getItem('currentUser')) || '';
  const username = currentLocalStorage4User['username'] || '';
  const password = currentLocalStorage4User['password'] ?
      CryptoService.decryptString(currentLocalStorage4User['password']) :
      CryptoService.decryptString('')

  if (serverURL && gpCrmUserId && username && password) {
    const params = {
      grant_type: 'password',
      pbx_clip: data.caller.original_number
          ? data.caller.original_number
          : data.caller.number,
      pbx_did: data.called_did ? data.called_did : null,
      pbx_extension: data.called_extension ? data.called_extension : null,
      pbx_timestamp: data.timestamp ? data.timestamp : null,
      pbx_uuid,
      pbx_user: username,
      pbx_password: password,
      'gp-crm_userId': gpCrmUserId
    };

    try {
      axios
          .post(serverURL, params)
          .then(response => {
            if (response.status === 200) {
              console.log("[RESPONSE SERVER]:")
              sendNotification('Call forwarded');
            }
          })
          .catch(error => {
            console.log('ERROR:', error);
            sendNotification('Server unavailable, cannot perform a search');
          });
    } catch (error) {
      console.error('ERROR TRY:', error);
    }
  }

  console.log('Redirect to medicom done');
};

const connectCRM4FindPhone = number => {
  let currentLocalStorage = window.localStorage.getItem('crm-config') ? JSON.parse(window.localStorage.getItem('crm-config'))['salesforce'] : '';
  const username = currentLocalStorage['username'] || '';
  const password = currentLocalStorage['password'] ?
      CryptoService.decryptString(currentLocalStorage['password']) :
      CryptoService.decryptString('');

  const clientId = currentLocalStorage['consumerKey'] ?
        CryptoService.decryptString(currentLocalStorage['consumerKey']) :
        CryptoService.decryptString('');

  const clientSecret = currentLocalStorage['consumerSecret'] ?
       CryptoService.decryptString(currentLocalStorage['consumerSecret']) :
       CryptoService.decryptString('');

  const securityToken = currentLocalStorage['securityToken'] ?
       CryptoService.decryptString(currentLocalStorage['securityToken']) :
       CryptoService.decryptString(null);

  if (clientId && clientSecret && username && password) {
    const params = {
      grant_type: 'password',
      client_id: clientId,
      client_secret: clientSecret,
      username,
      password: `${password}${securityToken}`
    };

    const url = `${'https://login.salesforce.com/services/oauth2/token?' +
      'grant_type='}${params.grant_type}&client_id=${
      params.client_id
    }&client_secret=${params.client_secret}&username=${
      params.username
    }&password=${encodeURIComponent(params.password)}`;

    try {
      axios
        .post(url, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          const { data } = response;
          const currentNumber = normalizedNumber(number);
          const urlFind = `${data.instance_url}/services/data/v48.0/parameterizedSearch/?q=${currentNumber}&fields=name&sobject=Account&sobject=Contact`;
          const auth = `${data.token_type} ${data.access_token}`;
          axios
            .get(urlFind, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: auth
              }
            })
            .then(res => {
              handlerSearchResponse(res.data.searchRecords, data.instance_url);
            })
            .catch(error => {
              console.log('ERROR search result:', error);
              sendNotification('Server unavailable, cannot perform a search');
            });
        })
        .catch(error => {
          console.log('ERROR Authorization:', error);
          sendNotification('Authorization error, cannot perform a search');
        });
    } catch (error) {
      console.error('ERROR TRY:', error);
    }
  }
};

const handlerSearchResponse = (searchRecords, url) => {
  if (searchRecords.length > 0) {
    const externalUrl = `${url}/${searchRecords[0].Id}`;
    window.openWindow(externalUrl);
  } else {
    sendNotification('Cannot find a matching number');
  }
};

const normalizedNumber = number => {
  return Number(
    number
      .split(' ')
      .join('')
      .split('-')
      .join('')
      .split('(')
      .join('')
      .split(')')
      .join('')
  ).toString();
}

export { connectCRM4FindPhone, redirectToMedicom, redirectToConnectors };
