import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import connectorsAction from '../../actions/connectorsAction';
import SalesForcePage from "./SalesForcePage";
import MedicomPage from "./MedicomPage";
import ConnectorPage from './ConnectorPage';
import URLBuilderPage from "./URLBuilder";

class ConnectorsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iconParamsSF: 'down',
      iconParamsM: 'down',
      paramsVisibleSF: false,
      paramsVisibleM: false,
      paramsVisibleUB: false
    };
  }

  componentDidMount() {
    if (this.props.signedIn) {
      this.props.connectors.fetchSalesforce();
      this.props.connectors.fetchMedicom();
    }
  }

  componentWillUnmount() {
    !this.props.connectSalesforce.serverURL && this.props.connectors.setSalesforceConnection();
    !this.props.connectMedicom.gpCrmUserId && this.props.connectors.setMedicomConnection()
  }

  onSalesforceToggleChange = checked => {
    this.props.connectors.setSalesforceConnection(checked);
    !checked && this.setState({
      iconParamsSF: 'down',
      paramsVisibleSF: false
    });
  };

  onMedicomToggleChange = checked => {
    this.props.connectors.setMedicomConnection(checked);
    !checked && this.setState({
      iconParamsM: 'down',
      paramsVisibleM: false
    });
  };

  hideShowParamsSF = () => {
    let currentIcon = this.state.iconParams === 'down' ? 'up' : 'down';
    this.setState({
      iconParamsSF: currentIcon,
      paramsVisibleSF: !this.state.paramsVisibleSF
    });
  }

  hideShowParamsM = () => {
    let currentIcon = this.state.iconParamsM === 'down' ? 'up' : 'down';
    this.setState({
      iconParamsM: currentIcon,
      paramsVisibleM: !this.state.paramsVisibleM
    });
  }

  hideShowParamsUB = () => {
    let currentIcon = this.state.iconParamsUB === 'down' ? 'up' : 'down';
    this.setState({
      iconParamsUB: currentIcon,
      paramsVisibleUB: !this.state.paramsVisibleUB
    });
  }

  render() {
    let stateConnectorSF = !!this.props.connectSalesforce.enabled;
    let stateConnectorMedicom = !!this.props.connectMedicom.enabled;
    const {
      iconParamsSF,
      paramsVisibleSF,
      iconParamsM,
      paramsVisibleM,
      paramsVisibleUB
    } = this.state;

    return this.props.crm_connector ? (
        <div>
          <div className="ui padded basic segment">
            <h2 className="ui header">
              <i className="handshake outline icon"/>
              <div className="content">Connectors CRM</div>
            </h2>
          </div>
          <div className="ui content" style={{overflow: 'auto'}}>
            {/******************************************
             SALESFORCE
             *******************************************/}
            <div className="ui padded basic segment">
              <h3 className="ui header">
                <div className="content">
                  Salesforce integration
                  <Popup
                      trigger={
                        <Icon
                            style={{paddingLeft: '3px', position: 'absolute'}}
                            name="question circle outline"
                            size="small"
                        />
                      }
                      content="Enable to enter SalesForce account details"
                      position="right center"
                  />
                </div>
              </h3>
              {stateConnectorSF &&
              <button
                  className="circular ui icon button"
                  onClick={this.hideShowParamsSF.bind(this)}
                  style={{float: 'right', background: '#c0c0c0'}}
              >
                <i className={`angle ${iconParamsSF} icon`}/>
              </button>}
              <div className="ui toggle checkbox">
                <input
                    type="checkbox"
                    name="salesforce"
                    checked={stateConnectorSF}
                    onChange={e => this.onSalesforceToggleChange(e.target.checked)}
                />
                <label>
                  <h4 className="ui header">
                    <div className="content">Enabled</div>
                  </h4>
                </label>
              </div>
              <div className="ui section divider"/>
              {paramsVisibleSF && <SalesForcePage/>}
            </div>
          </div>

          <div className="ui content" style={{overflow: 'auto'}}>
            {/******************************************
             MEDICOM
             *******************************************/}
            <div className="ui padded basic segment">
              <h3 className="ui header">
                <div className="content">
                  Medicom integration
                  <Popup
                      trigger={
                        <Icon
                            style={{paddingLeft: '3px', position: 'absolute'}}
                            name="question circle outline"
                            size="small"
                        />
                      }
                      content="Enable to enter Medicom/Hapicom account details"
                      position="right center"
                  />
                </div>
              </h3>
              {stateConnectorMedicom &&
              <button
                  className="circular ui icon button"
                  onClick={this.hideShowParamsM.bind(this)}
                  style={{float: 'right', background: '#c0c0c0'}}
              >
                <i className={`angle ${iconParamsM} icon`}/>
              </button>}
              <div className="ui toggle checkbox">
                <input
                    type="checkbox"
                    name="salesforce"
                    checked={stateConnectorMedicom}
                    onChange={e => this.onMedicomToggleChange(e.target.checked)}
                />
                <label>
                  <h4 className="ui header">
                    <div className="content">Enabled</div>
                  </h4>
                </label>
              </div>
              <div className="ui section divider"/>
              {paramsVisibleM && < MedicomPage/>}
            </div>
          </div>

          {/** ****************************************
           Connectors
           ****************************************** */}
          {this.props.templates.length > 0 &&
          this.props.templates.map((connector, index) => (
              <div
                  key={index}
                  className="ui container"
                  style={{ overflow: 'auto' }}
              >
                <div className="ui padded basic segment">
                  <h3 className="ui header">{connector.key}</h3>
                  <div
                      className="ui toggle checkbox"
                      style={{ marginBottom: '15px' }}
                  >
                    <input
                        type="checkbox"
                        name="salesforce"
                        checked={connector.enabled}
                        onChange={e => this.props.connectors.updateEnableConnector(connector, e.target.checked)}
                    />
                    <label>
                      <h4 className="ui header">
                        <div className="content">Enabled</div>
                      </h4>
                    </label>
                  </div>
                  {connector.enabled && (
                      <ConnectorPage key={`${connector.key}`} data={connector} />
                  )}
                  <div className="ui section divider" />
                </div>
              </div>
          ))}

          <div className="ui content" style={{overflow: 'auto'}}>
            {/******************************************
             URL Builder
             *******************************************/}
            <div className="ui padded basic segment">
              <URLBuilderPage
                  openModal={paramsVisibleUB}
                  closeModal={this.hideShowParamsUB}
              />
            </div>
          </div>
          <button
              type="button"
              className="circular ui icon button"
              title="Connector Builder with custom fields"
              onClick={this.hideShowParamsUB.bind(this)}
              style={{
                position: 'fixed',
                background: '#c0c0c0',
                bottom: '5%',
                right: '20%'
              }}
          >
            <i className="ui big handshake outline icon" />
          </button>
        </div>
    ) : (
        <div className="ui padded basic segment">
          <h2 className="ui header">
            <i className="info big icon"/>
            <div className="content">
              Please contact your administrator in order to gain access to CRM connector!
            </div>
          </h2>
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    signedIn: state.authentication,
    connectSalesforce: state.connectors.salesforce,
    connectMedicom: state.connectors.medicom,
    crm_connector: state.settings.crm_connector,
    templates: state.connectors.templates
  };
};

const mapDispatchToProps = dispatch => {
  return {
    connectors: bindActionCreators(connectorsAction, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectorsTab);
