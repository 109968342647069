import React from 'react';
import {NavLink} from 'react-router-dom';
import {Dropdown} from "semantic-ui-react";

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleMenu: true
        }
    }

    componentDidMount() {
        let currentVisible = window.innerWidth < 480;
        this.setState({
            visibleMenu: !currentVisible
        })
    }

    render() {
        return (
            <div>
                {!this.state.visibleMenu &&
                <Dropdown item icon="large sidebar">
                    <Dropdown.Menu>
                        <NavLink
                            exact
                            activeClassName="active orange"
                            to="/"
                            className="item"
                        >
                            Settings
                        </NavLink>
                        <NavLink
                            exact
                            activeClassName="active orange"
                            to="/phones"
                            className="item"
                        >
                            Phones
                        </NavLink>
                        <NavLink
                            exact
                            activeClassName="active orange"
                            to="/switches"
                            className="item"
                        >
                            Switches
                        </NavLink>
                        <NavLink
                            exact
                            activeClassName="active orange"
                            to="/connectorsTab"
                            className="item"
                        >
                            Connectors Tab
                        </NavLink>
                        <NavLink
                            exact
                            activeClassName="active orange"
                            to="/plugins"
                            className="item"
                        >
                            Plugins
                        </NavLink>
                        <NavLink
                            exact
                            activeClassName="active orange"
                            to="/acdSettings"
                            className="item"
                        >
                            ACD Settings
                        </NavLink>
                        <NavLink
                            exact
                            activeClassName="active orange"
                            to="/windows/"
                            className="item"
                        >
                            ACD Stats
                        </NavLink>
                    </Dropdown.Menu>
                </Dropdown>}

                {this.state.visibleMenu &&
                <div aria-label="sidebar" className="ui tabular stackable menu">
                    <NavLink
                        exact
                        activeClassName="active orange"
                        to="/"
                        className="item"
                    >
                        Settings
                    </NavLink>
                    <NavLink
                        exact
                        activeClassName="active orange"
                        to="/phones"
                        className="item"
                    >
                        Phones
                    </NavLink>
                    <NavLink
                        exact
                        activeClassName="active orange"
                        to="/switches"
                        className="item"
                    >
                        Switches
                    </NavLink>
                    <NavLink
                        exact
                        activeClassName="active orange"
                        to="/connectorsTab"
                        className="item"
                    >
                        Connectors Tab
                    </NavLink>
                    <NavLink
                        exact
                        activeClassName="active orange"
                        to="/plugins"
                        className="item"
                    >
                        Plugins
                    </NavLink>
                    <NavLink
                        exact
                        activeClassName="active orange"
                        to="/acdSettings"
                        className="item"
                    >
                        ACD Settings
                    </NavLink>
                    <NavLink
                        exact
                        activeClassName="active orange"
                        to="/windows/"
                        className="item"
                    >
                        ACD Stats
                    </NavLink>
                </div>}
            </div>
        );
    }
}

export default Sidebar;
