import { sendNotification } from './notificationHelper';

const queueSavedValue = {};

const checkThresholds = queues => {
  const acdQueuesLocalStorage = JSON.parse(window.localStorage.getItem('acdQueues')) || false;
  let acdQueuesLocalStorageLength = Object.entries(acdQueuesLocalStorage).length;
  if (queues && acdQueuesLocalStorage) {

    if (queues.length > acdQueuesLocalStorageLength) {
      window.localStorage.setItem('acdQueuesLocalStorage', JSON.stringify(queues));
    }

    queues.map(queue => {
      const uuidValue = queue.uuid;
      let message = `Threshold interval exceeded for Queue`;
      const messageLength = message.length;

      if (!queueSavedValue.hasOwnProperty(uuidValue)) {
        queueSavedValue[uuidValue] = {
          calls_waiting: queue.calls_waiting,
          longest_hold_time: queue.longest_hold_time,
          free_agents: queue.free_agents
        };
      }

      const queueSavedValueElement = queueSavedValue[uuidValue];
      const acdQueuesLocalStorageElement = acdQueuesLocalStorage[uuidValue];

      if (
        acdQueuesLocalStorageElement.waitingCallsShowNotification &&
        queue.calls_waiting >= acdQueuesLocalStorageElement.waitingCallsGreaterValue &&
        queueSavedValueElement.calls_waiting !== queue.calls_waiting
      ) {
        message += ` "${queue.name}" \nCalls: ${queue.calls_waiting}`;
        queueSavedValueElement.calls_waiting = queue.calls_waiting;
      }

      if (
        acdQueuesLocalStorageElement.longestWaitShowNotification &&
        queue.longest_hold_time >= acdQueuesLocalStorageElement.longestWaitGreaterValue &&
        queueSavedValueElement.longest_hold_time !== queue.longest_hold_time
      ) {
        message += ` "${queue.name}" \nLongest: ${queue.longest_hold_time}`;
        queueSavedValueElement.longest_hold_time =
          queue.longest_hold_time +
          acdQueuesLocalStorageElement.longestWaitGreaterValueMin;
      }

      if (
        acdQueuesLocalStorageElement.agentsShowNotification &&
        queue.free_agents <= acdQueuesLocalStorageElement.agentsLessValue &&
        queueSavedValueElement.free_agents !== queue.free_agents
      ) {
        message += ` "${queue.name}" \nAgents: ${queue.free_agents}`;
        queueSavedValueElement.free_agents = queue.free_agents;
      }

      message.length > messageLength && sendNotification(message);
      return null
    });
  }
};

export default checkThresholds;
