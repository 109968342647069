// @flow
import React, { Fragment, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import windowRoutes from '../constants/windowRoutes';
import Content from '../containers/layout/Content';
import LoginForm from './layout/LoginForm';
import authenticationActions from '../actions/authenticationActions';

const App = props => {
  let currentLocalStorage = JSON.parse(window.localStorage.getItem('currentUser'));
  useEffect(() => {
    if (currentLocalStorage) {
      props.authenticationActions.loginCheck({
        serverURL: currentLocalStorage['serverURL'],
        username: currentLocalStorage['username'],
        password: currentLocalStorage['password'] || ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <Switch>
        {props.authentication.isLoggedIn ? (
            <Fragment>
              {windowRoutes.map((route, i) => (
                  <Route
                      key={i}
                      exact={route.exact}
                      path={route.path}
                      name={route.name}
                  >
                    <Content>{<route.component {...route} identifier={i}/>}</Content>
                  </Route>
              ))}
            </Fragment>
        ) : (
            <LoginForm/>
        )}
      </Switch>
  );
};

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    pathname: state.router.location.pathname
  };
};

const mapDispatchToProps = dispatch => {
  return {
    authenticationActions: bindActionCreators(authenticationActions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
