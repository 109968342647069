import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import authenticationActions from '../../actions/authenticationActions';

class Login extends React.Component {
  render() {
    const validationSchema = Yup.object({
      serverURL: Yup.string().required(),
      username: Yup.string().required('Username is required'),
      password: Yup.string().required('Password is required')
    });

    return ReactDOM.createPortal(
      <Formik
        initialValues={{
          serverURL: '',
          username: '',
          password: ''
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          this.props.authenticationActions.loginCheck(values, true);
          setSubmitting(false);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          isSubmitting
        }) => {
          return (
            <div className="ui dimmer modals visible active">
              <div className="ui standard modal visible active">
                <div className="header">AXEOS UC Manager</div>
                <div className="content">
                  <div
                    className={`ui negative message ${
                      this.props.authentication.errorMessage
                        ? 'visible'
                        : 'hidden'
                    }`}
                  >
                    <i
                      className="close icon"
                      onClick={() =>
                        this.props.authenticationActions.resetErrorMessage()
                      }
                    />
                    <div className="header">SOMETHING WENT WRONG</div>
                    {this.props.authentication.errorMessage}
                  </div>
                  <form className="ui form" onSubmit={handleSubmit}>
                    <div className="field">
                      <label>Server URL</label>
                      <input
                        type="text"
                        name="serverURL"
                        placeholder="server URL"
                        onChange={handleChange}
                      />
                      {touched.serverURL && errors.serverURL && (
                        <div className="description">{errors.serverURL}</div>
                      )}
                    </div>
                    <div className="field">
                      <label>Username</label>
                      <input
                        type="text"
                        name="username"
                        placeholder="Username"
                        onChange={handleChange}
                      />
                      {touched.username && errors.username && (
                        <div className="description">{errors.username}</div>
                      )}
                    </div>
                    <div className="field">
                      <label>Password</label>
                      <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        autoComplete="on"
                        onChange={handleChange}
                      />
                      {touched.password && errors.password && (
                        <div className="description">{errors.password}</div>
                      )}
                    </div>
                    <div className="ui hidden divider" />
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className={`ui fluid primary button ${
                        isSubmitting ? 'loading' : ''
                      }`}
                    >
                      {isSubmitting ? 'Logging in...' : 'Log In'}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>,
      document.querySelector('#login')
    );
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication
  };
};

const mapDispatchToProps = dispatch => {
  return {
    authenticationActions: bindActionCreators(authenticationActions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
