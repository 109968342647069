export const DEFAULT_QUEUE_VALUE = {
  waitingCallsGreaterValueMin: 1,
  waitingCallsGreaterValue: 4,
  waitingCallsShowNotification: true,
  longestWaitGreaterValueMin: 60,
  longestWaitGreaterValue: 120,
  longestWaitShowNotification: true,
  agentsLessValue: 1,
  agentsLessValueMax: 4,
  agentsShowNotification: true
}

// ucm-proxy-server
export const PROXY_SERVER = 'https://ucm-proxy.axeos.nl';
