const crypto = require('crypto');
const key = 'xxtfbpk,8A6RC}iwXMvFpyJDDdEeiZnR';
const iv = crypto.randomBytes(16);

export default class CryptoService {
  static encryptString(text) {
    let cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key), iv);
    let encrypted = cipher.update(text);
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    return { iv: iv.toString('hex'), encryptedData: encrypted.toString('hex') };
  }

  static decryptString(text) {
    if ('iv' in text && 'encryptedData' in text) {
      let iv = Buffer.from(text.iv, 'hex');
      let encryptedText = Buffer.from(text.encryptedData, 'hex');
      let decipher = crypto.createDecipheriv(
        'aes-256-cbc',
        Buffer.from(key),
        iv
      );
      let decrypted = decipher.update(encryptedText);
      decrypted = Buffer.concat([decrypted, decipher.final()]);
      return decrypted.toString();
    } else {
      return text;
    }
  }
}
