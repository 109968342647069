import ApiService from '../services/apiService';
import {apiRoutes} from '../constants/apiRoutes';
import {actionTypes} from '../constants/actionTypes';
import internalDialHelper from '../helpers/internalDialHelper';
import settingsActions from './settingsActions';
import phonesActions from "./phonesActions";

const fetchUsers = () => {
    return async dispatch => {
        const response = await ApiService.performRequest(
            'GET',
            apiRoutes.users.list
        );

        dispatch({
            type: actionTypes.user.FETCH_USERS,
            payload: response.data
        });
    };
};

const fetchUserByIdWithStatus = () => {
    return async (dispatch, getState) => {
        const {userId} = getState().authentication;
        let url = apiRoutes.users.get.replace('{uuid}', userId);

        const response = await ApiService.performRequest('GET', url);

        //check personal for primary phone
        const {primaryPhone} = getState().settings;
        if (primaryPhone.uuid) {
            let checkPrimaryPhones = response.data.phones.filter(phone => primaryPhone.uuid === phone.phone);
            if (checkPrimaryPhones.length && !checkPrimaryPhones[0].personal) {
                dispatch(settingsActions.setPrimaryPhone(null, null))
            }
        }

        dispatch({
            type: actionTypes.user.FETCH_USER_STATUS,
            payload: response.data
        });
    };
};

let lastSeq = 0;

const eventListening = () => {
    return async dispatch => {
        let isLoggedIn = JSON.parse(window.localStorage.getItem('currentUser'))['isLoggedIn'] || false;

        const pbxEvents = async (seq = 0) => {
            lastSeq = seq;

            const params = {
                limit: 5,
                last: lastSeq
            };

            const response = await ApiService.performRequest(
                'GET',
                apiRoutes.events.get,
                {
                    params
                }
            );

            if (response.data[response.data.length - 1] !== undefined) {
                if (lastSeq !== 0) {
                    console.log('[response.data[0]]: ', response.data[0]);
                    switch (response.data[0].event_type) {
                        case 'internal dial':
                            internalDialHelper(response.data[0]);
                            console.log('internal dial')
                            break;
                        case 'blind transfer' || 'attended transfer':
                            internalDialHelper(response.data[0]);
                            break;
                        case 'user logged in':
                            dispatch(fetchUserByIdWithStatus());
                            dispatch(phonesActions.fetchPhones());
                            break;
                        case 'user logged out':
                            dispatch(fetchUserByIdWithStatus());
                            dispatch(phonesActions.fetchPhones());
                            break;
                        default:
                            break;
                    }
                }

                lastSeq = response.data[response.data.length - 1].seq;
            }

            window.localStorage.setItem('lastSeq', lastSeq);
            if (window.localStorage.length > 1 &&
                window.localStorage.getItem('currentUser') &&
                JSON.parse(window.localStorage.getItem('currentUser'))['isLoggedIn'])
            {
                await pbxEvents(lastSeq);
            }
        };
        isLoggedIn && await pbxEvents();

        dispatch({
            type: actionTypes.user.EVENT_LISTENING
        });
    };
};

export default {
    fetchUserByIdWithStatus,
    fetchUsers,
    eventListening
};
