import { incomingCallNotification, sendNotification } from './notificationHelper';
import {
    redirectToMedicom,
    connectCRM4FindPhone,
    redirectToConnectors
} from './connectorsHelper';
import path from 'path';

const iconPath = path.join(__dirname, 'resources/ucmIconMac.png');

const internalDialHelper = data => {
    let caller;
    if (data.event_type === 'internal dial') {
        if (data.hasOwnProperty('caller')) {
            ({ caller } = data);
        }
    }
    if (
        data.event_type === 'blind transfer' ||
        data.event_type === 'attended transfer'
    ) {
        if (data.hasOwnProperty('transferee')) {
            caller = data.transferee;
        }
    }

    const crmConnectorEnabled = window.localStorage.getItem('crm-connector') ? window.localStorage.getItem('crm-connector') : false;
    const connectorData = window.localStorage.getItem('crm-config') ? JSON.parse(window.localStorage.getItem('crm-config')) : false;

    const connectorSalesForce = connectorData ? connectorData['salesforce']['enabled'] : false;
    const connectorMedicom = connectorData ? connectorData['medicom']['enabled'] : false;
    const forwardAll = connectorData ? connectorData['medicom']['forwardAll'] : false;

    const connectors = connectorData ? connectorData['templates'] : [];

    if (caller.number === 'anonymous') {
        sendNotification('Anonymous call, without a phone number, cannot perform a search');
    } else if (crmConnectorEnabled) {
        if (connectorSalesForce) {
            const note = new Notification('UC Manager', {
                icon: iconPath,
                body: `Incoming call from (${caller.number}). Click to search in connected CRM`,
                dir: 'auto',
                requireInteraction: true
            });
            note.addEventListener('click', () => {
                console.log("CLICK");
                connectCRM4FindPhone(caller.number);
            });
        }

        // handle incoming call with Medicom connector
        if (connectorMedicom) {
            let note;
            if (forwardAll) {
                sendNotification(`Incoming call from (${caller.number}). Redirected to Medicom`);
                console.log('Incoming call from. Redirected to Medicom')
                redirectToMedicom(data);
            } else {
                note = new Notification('UC Manager', {
                    icon: iconPath,
                    body: `Incoming call from (${caller.number}). Click to redirect to Medicom`,
                    dir: 'auto',
                    requireInteraction: true
                });
                console.log('Incoming call from. Click to redirect to Medicom')
                note.addEventListener('click', () => {
                    console.log("CLICK");
                    redirectToMedicom(data);
                });
            }
            console.log('handle incoming call with Medicom connector');
        }

        // handle incoming call for rest of connectors created URLBuilder
        if (connectors.length > 0) {
            let resultArr = connectors.filter(connector => connector.enabled)
            resultArr.length > 0 && resultArr.map(connector => {
                const note = new Notification('UC Manager', {
                    icon: iconPath,
                    body: `Incoming call from (${caller.number}). Click to redirect to open ${connector.key}`,
                    dir: 'auto',
                    requireInteraction: true
                });
                note.addEventListener('click', () => {
                    redirectToConnectors(false, connector, caller);
                });
                return null
            });
        }

        if (!connectorSalesForce && !connectorMedicom && connectors.length === 0) {
            incomingCallNotification(caller);
        }
    } else {
        incomingCallNotification(caller);
    }
}

export default internalDialHelper
