const apiRoutes = {
  baseUrl: '/apis/pbx',

  sys_info: '/system_information',

  events: {
    get: '/events'
  },

  users: {
    list: '/users?appid=ucmanager',
    get: '/users/{uuid}',
    status: '/users/{uuid}?status=1'
  },

  switch: {
    list: '/switches?status=1',
    post: '/switches/{uuid}'
  },

  phones: {
    list: '/phones',
    get: '/phones/{uuid}'
  },

  call: {
    post: '/call'
  },

  stats: {
    get: '/acd/stats'
  },

  update: 'https://nexus.axeos.nl/repository/ucmanager-updates/',
};

export { apiRoutes };
