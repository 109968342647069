// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Checkbox,
  Icon,
  Input,
  Table,
  Header,
  Divider,
  Button
} from 'semantic-ui-react';

import { DEFAULT_QUEUE_VALUE } from '../../constants/mainConst';
import acdStatsActions from '../../actions/acdStatsActions';

class AcdSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedQueue: null,
      selectedQueueValues: {
        waitingCallsGreaterValueMin: 3,
        waitingCallsGreaterValue: 7,
        waitingCallsShowNotification: false,
        longestWaitGreaterValueMin: 60,
        longestWaitGreaterValue: 120,
        longestWaitShowNotification: false,
        agentsLessValue: 4,
        agentsLessValueMax: 7,
        agentsShowNotification: false
      },
      defaultQueueValues: DEFAULT_QUEUE_VALUE
    };
  }

  componentDidMount() {
    this.props.acdStatsActions.fetchStats();
  }

  handleClick = queue => {
    const localQueuesValues = JSON.parse(window.localStorage.getItem('acdQueues')) || '';
    const uuid = queue.uuid;

    if (localQueuesValues.hasOwnProperty(uuid)) {
      this.setState({
        selectedQueueValues: localQueuesValues[uuid],
        selectedQueue: queue
      });
    } else {
      window.localStorage.setItem('acdQueues', JSON.stringify(uuid[this.state.defaultQueueValues]));
      this.setState({
        selectedQueueValues: this.state.defaultQueueValues,
        selectedQueue: queue
      });
    }
  };

  handleOnChange = (e, type, checkbox = null) => {
    this.setState({
      selectedQueueValues: {
        ...this.state.selectedQueueValues,
        [type]: parseInt(e.target.value)
      }
    });

    const localQueuesValues = JSON.parse(window.localStorage.getItem('acdQueues')) || '';
    localQueuesValues[this.state.selectedQueue.uuid] = {
      ...this.state.selectedQueueValues,
      [type]: parseInt(e.target.value)
    }
    window.localStorage.setItem('acdQueues', JSON.stringify(localQueuesValues));
  };

  handleChangeCheckBox = (e, { checked }, type) => {
    this.setState({
      selectedQueueValues: {
        ...this.state.selectedQueueValues,
        [type]: checked
      }
    });

    const localQueuesValues = JSON.parse(window.localStorage.getItem('acdQueues')) || '';
    localQueuesValues[this.state.selectedQueue.uuid] = {
      ...this.state.selectedQueueValues,
      [type]: checked
    }

    window.localStorage.setItem('acdQueues', JSON.stringify(localQueuesValues));
  };

  resetDefault = () => {
    const uuid = this.state.selectedQueue.uuid;

    const localQueuesValues = JSON.parse(window.localStorage.getItem('acdQueues')) || '';
    localQueuesValues[uuid] = this.state.defaultQueueValues;

    window.localStorage.setItem('acdQueues', JSON.stringify(localQueuesValues));
    this.setState({
      selectedQueueValues: this.state.defaultQueueValues
    });
  };

  saveChanges = () => {};

  render() {
    return this.props.acd_tool ? (
      <div>
        <Button.Group
          style={{
            overflow: 'auto',
            paddingBottom: '2px',
            margin: '5px 0 15px 0'
          }}
        >
          {this.props.stats.map(queue => {
            return (
              <Button
                key={queue.uuid}
                className={(this.state.selectedQueue && this.state.selectedQueue.uuid === queue.uuid)? "active" : null}
                onClick={() => this.handleClick(queue)}>
                {queue.name}
              </Button>
            );
          })}
        </Button.Group>
        {this.state.selectedQueueValues !== null ? (
          <Divider horizontal>
            <Header as="h4">
              <Icon name="bar chart" />
              Queue notifications:
            </Header>
          </Divider>
        ) : null}
        {this.state.selectedQueue !== null ? (
          <Table unstackable definition>
            <Table.Header fullWidth>
              <Table.Row>
                <Table.HeaderCell>Notify</Table.HeaderCell>
                <Table.HeaderCell>Threshold</Table.HeaderCell>
                <Table.HeaderCell>{'<='}</Table.HeaderCell>
                <Table.HeaderCell>{'>='}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing>
                  <Checkbox
                    toggle
                    checked={
                      this.state.selectedQueueValues.waitingCallsShowNotification
                    }
                    onClick={(e, { checked }) =>
                      this.handleChangeCheckBox(
                        e,
                        { checked },
                        'waitingCallsShowNotification'
                      )
                    }
                  />
                </Table.Cell>
                <Table.Cell>Waiting calls</Table.Cell>
                <Table.Cell error>
                  <Input
                    style={{ width: '80px' }}
                    type={'number'}
                    value={
                      this.state.selectedQueueValues.waitingCallsGreaterValueMin
                    }
                    onChange={e =>
                      this.handleOnChange(e, 'waitingCallsGreaterValueMin')
                    }
                    min={1}
                    max={
                      this.state.selectedQueueValues.waitingCallsGreaterValue
                    }
                  />
                </Table.Cell>
                <Table.Cell error>
                  <Input
                    style={{ width: '80px' }}
                    type={'number'}
                    value={
                      this.state.selectedQueueValues.waitingCallsGreaterValue
                    }
                    onChange={e =>
                      this.handleOnChange(e, 'waitingCallsGreaterValue')
                    }
                    min={1}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <Checkbox
                    toggle
                    checked={
                      this.state.selectedQueueValues.longestWaitShowNotification
                    }
                    onChange={(e, { checked }) =>
                      this.handleChangeCheckBox(
                        e,
                        { checked },
                        'longestWaitShowNotification'
                      )
                    }
                  />
                </Table.Cell>
                <Table.Cell>Longest wait time (sec)</Table.Cell>
                <Table.Cell error>
                  <Input
                    style={{ width: '80px' }}
                    type={'number'}
                    value={
                      this.state.selectedQueueValues.longestWaitGreaterValueMin
                    }
                    onChange={e =>
                      this.handleOnChange(e, 'longestWaitGreaterValueMin')
                    }
                    min={5}
                    max={this.state.selectedQueueValues.longestWaitGreaterValue}
                  />
                </Table.Cell>
                <Table.Cell error>
                  <Input
                    style={{ width: '80px' }}
                    type={'number'}
                    value={
                      this.state.selectedQueueValues.longestWaitGreaterValue
                    }
                    onChange={e =>
                      this.handleOnChange(e, 'longestWaitGreaterValue')
                    }
                    min={5}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell collapsing>
                  <Checkbox
                    toggle
                    checked={
                      this.state.selectedQueueValues.agentsShowNotification
                    }
                    onChange={(e, { checked }) =>
                      this.handleChangeCheckBox(
                        e,
                        { checked },
                        'agentsShowNotification'
                      )
                    }
                  />
                </Table.Cell>
                <Table.Cell>Agents</Table.Cell>
                <Table.Cell error>
                  <Input
                    style={{ width: '80px' }}
                    type={'number'}
                    value={this.state.selectedQueueValues.agentsLessValue}
                    onChange={e => this.handleOnChange(e, 'agentsLessValue')}
                    min={1}
                    max={this.state.selectedQueueValues.agentsLessValueMax}
                  />
                </Table.Cell>
                <Table.Cell error>
                  <Input
                    style={{ width: '80px' }}
                    type={'number'}
                    value={this.state.selectedQueueValues.agentsLessValueMax}
                    onChange={e => this.handleOnChange(e, 'agentsLessValueMax')}
                    min={1}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        ) : (
          ''
        )}
        <br />
        {this.state.selectedQueue !== null ? (
          <div>
            <Button primary onClick={this.saveChanges}>
              Save changes
            </Button>
            <Button secondary onClick={this.resetDefault}>
              Reset values
            </Button>
          </div>
        ) : (
          ''
        )}
      </div>
    ) : (
      <div className="ui padded basic segment">
        <h2 className="ui header">
          <i className="info big icon" />
          <div className="content">
            Please contact your administrator in order to gain access to ACD
            stats!
          </div>
        </h2>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    stats: state.stats.queues,
    acd_tool: state.settings.acd_tool
  };
};

const mapDispatchToProps = dispatch => {
  return {
    acdStatsActions: bindActionCreators(acdStatsActions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcdSettings);
