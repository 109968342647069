import ApiService from '../services/apiService';
import { apiRoutes } from '../constants/apiRoutes';
import { sendNotification } from './notificationHelper';

export const callFromClipboard = async number => {
  let numberWithDialPrefix = number;

  const dialPrefixNumber = window.localStorage.getItem('dialPrefix') ?
      JSON.parse(window.localStorage.getItem('dialPrefix'))['number'] : null;

  if (
    dialPrefixNumber !== 0 &&
    dialPrefixNumber &&
    dialPrefixNumber % 1 === 0
  ) {
    numberWithDialPrefix = dialPrefixNumber + number;
  }

  sendNotification(`Calling ${numberWithDialPrefix}...`);
  const data = {
    number: numberWithDialPrefix,
    auto_answer: window.localStorage.getItem('autoAnswer') ? JSON.parse(window.localStorage.getItem('autoAnswer'))['enabled'] : false
  };

  try {
    const response = await ApiService.performRequest(
      'POST',
      apiRoutes.call.post,
      data
    );

    if (response.data.status === 'success') {
      sendNotification(`Call with ${numberWithDialPrefix} in progress.`);
    } else if (response.data.status === 'busy') {
      sendNotification(`Number ${numberWithDialPrefix} is busy.`);
    } else {
      sendNotification(`Error when calling number ${numberWithDialPrefix}`);
    }
  } catch (error) {
    sendNotification(`Service Unavailable`);
  }
};

/*
* string - axeos number regex format
*/
let axeosNumberRegex = /(?:\d{0,2}[( \t\-.)–]{0,2}(?:\+|(?:00))[ \t\-.()–]{0,3}\d{1,2})?(?:[ \t\-.()–]{0,5}\d{1,10}){5,10}[)]{0,3}/g
//					   \_________________________________________________________________________/\______________________________________________/
// 									prefix+country code (optional)                                 rest of the number


export const isContentValidAxeosNumber = (textContent) => {

  // other formats that are not valid numbers
  let invalidFormat = [];
  invalidFormat[0] =    /(?:[1-2][0-9]{3})[ \t]?[.()][ \t]?(?:[0-3][0-9])[ \t]?[.][ \t]?(?:[0-3][0-9])/g    	;// date formatting - 2014 [.-] 01 [.-] 01
  invalidFormat[1] =    /(?:[0-3][0-9][ \t]?[.][ \t]?){2}(?:[0-9]{4})/g                                            	;// date formatting -  01 [.-] 01 [.-] 2014
  invalidFormat[2] =    /(?:[1-2][0-9]{3})[ \t]?[.][ \t]?(?:[0-3][0-9])[ \t]?/g                                   	;// date - 2014 [.-] 01
  invalidFormat[3] =    /[1-2](?:[0-9]{3})[ \t]?[.][ \t]?[1-2](?:[0-9]{3})/g                                    		;// date - [1-2]914 [.-] [1-2]014
  invalidFormat[4] =    /(?:(?:[1-2][0-9][0-9]|[1-9][0-9]|[0-9])[.]){3}(?:[1-2][0-9][0-9]|[1-9][0-9]|[0-9])/g        		;// ip formatting
  invalidFormat[5] =    /[0-9]{1,2}:[0-9]{1,2}/g                                                                        	;// hour formatting​

  for (let key in invalidFormat) {
    textContent = textContent.replace(invalidFormat[key], "|");
  }

  var phoneNumberMatches = textContent.match(axeosNumberRegex);

  if(phoneNumberMatches === null || phoneNumberMatches.length === 0){
    return false;
  }

  return phoneNumberMatches;
}
