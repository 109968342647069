// @flow
import React from 'react';

class PluginsPage extends React.Component {
  render() {
    const buttonLinkStyle = {
      color: '#ffffff'
    };
    return (
      <div>
        <div className="ui padded basic segment">
          <h2 className="ui header">
            <i className="puzzle piece icon" />
            <div className="content">Plugins</div>
          </h2>
        </div>
        <div className="ui center aligned raised segment">
          <div className="ui header">
            <i className="large chrome icon" />
            Google Chrome
          </div>
          <div className="ui orange fluid button">
            <a
              style={buttonLinkStyle}
              href="https://chrome.google.com/webstore/detail/uc-manager/ocndcadaneceimkfpidpepichhfgonem"
              target="_blank"
              rel="noopener noreferrer"
            >
              INSTALL FROM CHROME STORE <i className="external alternate icon" />
            </a>
          </div>
        </div>
        <div className="ui center aligned raised segment">
          <div className="ui header">
            <i className="large firefox icon" />
            Mozilla Firefox
          </div>
          <div className="ui orange fluid button">
            <a
              style={buttonLinkStyle}
              href="https://addons.mozilla.org/en-US/firefox/addon/axeos-cti/"
              target="_blank"
              rel="noopener noreferrer"
            >
              INSTALL FROM APP STORE <i className="external alternate icon" />
            </a>
          </div>
        </div>
        <div className="ui center aligned raised segment">
          <h3 className="ui header">
            <i className="large edge icon" />
            Microsoft Edge
          </h3>
          <div className="ui orange fluid button">
            <a
                style={buttonLinkStyle}
                href="Microsoft-Edge:https://chrome.google.com/webstore/detail/uc-manager/ocndcadaneceimkfpidpepichhfgonem"
                target="_blank"
                rel="noopener noreferrer"
            >
              INSTALL FROM APP STORE <i className="external alternate icon" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default PluginsPage;
