import ApiService from '../services/apiService';
import { apiRoutes } from '../constants/apiRoutes';
import { actionTypes } from '../constants/actionTypes';
import CryptoService from '../services/cryptoService';
import settingsActions from './settingsActions';
import userActions from "./userActions";

// Make an api call with username & password to check if credentials are correct
const loginCheck = (credentials, first = false) => {
  return async dispatch => {
    try {
      // Dont perform check when no credentials are provided
      if (
        !credentials.serverURL ||
        !credentials.username ||
        !credentials.password
      ) {
        return;
      }

      dispatch({
        type: actionTypes.auth.LOGIN
      });

      if (!first) {
        credentials.password = CryptoService.decryptString(
          credentials.password
        );
      }

      credentials.serverURL = !(/(https:\/\/[^s]+)/).test(credentials.serverURL) ? `https://${credentials.serverURL}` : credentials.serverURL

      const response = await ApiService.performRequest(
        'GET',
        apiRoutes.users.list,
        null,
        credentials
      );

      const sys_info = await ApiService.performRequest(
          'GET',
          apiRoutes.sys_info,
          null,
          credentials
      );
      const pbx_uuid = sys_info.data.uuid;

      credentials.password = CryptoService.encryptString(credentials.password);
      credentials.userId = response.data[0].uuid;
      credentials.isLoggedIn = true;

      // Set local storage here
      window.localStorage.setItem('currentUser', JSON.stringify(credentials));
      window.localStorage.setItem('pbx_uuid', pbx_uuid);

      // Set API SEQ connection
      dispatch(settingsActions.setApiConnectionSeq());

      // Set ACD tool permission for ACD
      dispatch(settingsActions.setACDTool(response.data[0].acd_tool));

      // Set CRM connector permission
      dispatch(settingsActions.setCRMConnector(response.data[0].crm_connector));

      // Set connection for ACD stats
      response.data[0].acd_tool && dispatch(settingsActions.setACDConnection());

      //Set default queue value
      response.data[0].crm_connector && dispatch(settingsActions.setDefaultValueQueueValue())

      //start event listening
      dispatch(userActions.eventListening())

      dispatch({
        type: actionTypes.auth.LOGIN_SUCCESS,
        credentials
      });
    } catch (error) {
      // Show a generic error message
      let message =
        'Please make sure that you provided the correct login details and have an active internet connection.';

      console.log(error);
      window.localStorage.clear();

      // You can specify the error message below
      if (
        Object.prototype.hasOwnProperty.call(error, 'response') &&
        error.response
      ) {
        if (error.response.status === 401) {
          message = 'Incorrect username/password';
        } else if (error.response.status === 404) {
          message = 'Incorrect name server';
        }
      } else {
        message =
          'Cannot connect to server: invalid server name or SSL certificate';
      }

      dispatch({
        type: actionTypes.auth.LOGIN_FAIL,
        message
      });
    }
  };
};

const logOut = () => {
  return async dispatch => {
    window.localStorage.clear();

    dispatch({
      type: actionTypes.auth.LOGOUT
    });
  };
};

const resetErrorMessage = () => {
  return async dispatch => {
    dispatch({
      type: actionTypes.auth.LOGIN
    });
  };
};

export default {
  loginCheck,
  logOut,
  resetErrorMessage
};
