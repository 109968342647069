import React from 'react';
import ACDStatsPage from '../acdStats/ACDStatsPage';

class PageLayout extends React.Component {
  render() {
    return (
      <div>
        <ACDStatsPage />
      </div>
    );
  }
}
export default PageLayout;
