export const init = () => {
/* Notification */
if (window.Notification && Notification.permission !== "granted") {
    Notification.requestPermission()
        .then(status => {
            alert(`STATUS: ${status}`)
            if (Notification.permission !== status) {
                Notification.permission = status;
            }
        })
        .catch(reason => {
            console.log("Notification error: ", reason)
        });
}

// if (window.Notification && Notification.permission === "granted") {
//     console.log("NOTIFICATION");
//     let i = 0;
//     // Using an interval cause some browsers (including Firefox) are blocking notifications if there are too much in a certain time.
//     let interval = window.setInterval(function () {
//         // Thanks to the tag, we should only see the "Hi! 9" notification
//         let n = new Notification("Hi! " + i, {body: 'soManyNotification'});
//         if (i++ === 1) {
//             window.clearInterval(interval);
//         }
//     }, 1000);
// }

/* service worker */
if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
        navigator.serviceWorker.register('./service-worker.js').then(
            function(registration) {
                // Registration was successful
                console.log('ServiceWorker registration successful with scope: ', registration.scope); },
            function(err) {
                // registration failed :(
                console.log('ServiceWorker registration failed: ', err);
            });
    });
}

/* add to home screen */
let deferredPrompt;
const addBtn = document.getElementById('add-button');
addBtn.style.display = 'none';
window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    // Update UI to notify the user they can add to home screen
    addBtn.style.display = 'block';

    addBtn.addEventListener('click', (e) => {
        // hide our user interface that shows our A2HS button
        addBtn.style.display = 'none';
        // Show the prompt
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                console.log('User accepted the A2HS prompt');
            } else {
                console.log('User dismissed the A2HS prompt');
            }
            deferredPrompt = null;
        });
    });
});
}

