import path from 'path';

const iconPath = path.join(__dirname, 'resources/ucmIconMac.png');

const incomingCallNotification = caller => {
  sendNotification(`Incoming call from ${caller.name} (${caller.number})`);
};

const sendNotification = body => {
  if (window.Notification && Notification.permission === "granted") {
    new Notification('UC Manager', {
      body: body,
      icon: iconPath,
      dir: "auto"
    });
  } else if (window.Notification && Notification.permission !== "granted") {
    Notification.requestPermission()
        .then(status => {
          if (Notification.permission !== status) {
            Notification.permission = status;
          }
        })
        .catch(reason => {
          console.log("Notification error: ", reason)
        });
  } else {
    alert("Your notifications blocked")
  }
};

export { incomingCallNotification, sendNotification };
