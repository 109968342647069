import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import connectorsAction from '../../actions/connectorsAction';

class ConnectorPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serverURL: '',
            fields: [
                {
                    key: '',
                    value: ''
                }
            ],
            errorServer: false,
            sfActive: false
        };
    }

    componentDidMount() {
        const { data } = this.props;
        const { value } = data;
        const templatesArr = value.split('?');
        let serverURL;
        let fieldsArr;
        let fields;
        if (templatesArr.length > 1) {
            [serverURL, fieldsArr] = templatesArr;
            fields = fieldsArr.split('&').map(item => {
                const result = item.split('=');
                return { key: result[0], value: result[1] };
            });
            this.setState({
                serverURL,
                fields
            });
        } else {
            [serverURL] = templatesArr;
            this.setState({
                serverURL,
                fields: [
                    {
                        key: '',
                        value: ''
                    }
                ]
            });
        }
    }

    onDataSubmit = e => {
        e.preventDefault();
        const { sfActive } = this.state;

        if (!sfActive) {
            this.setState({ sfActive: true });
        } else {
            const errorServer = e.target.serverURL.value === '';
            const data = this.state;
            data.templateName = this.props.data.key;
            !errorServer && this.props.connectors.updateConnector(data);
            this.setState({ errorServer, sfActive: errorServer });
        }
    };

    onChangeField(index, event) {
        const currentName = event.target.name;
        const currentValue = event.target.value;

        if (index !== -1) {
            this.setState(state => {
                const fields = state.fields.map((item, j) => {
                    if (j === index) {
                        item[currentName] = currentValue;
                        return item;
                    }
                    return item;
                });
                return {
                    fields,
                    errorTemplatesName: false,
                    errorServer: false
                };
            });
        } else {
            this.setState({
                [event.target.name]: event.target.value.trim()
            });
        }
    }

    deleteTemplate() {
        const { key } = this.props.data;
        this.props.connectors.removeConnector(key);
    }

    render() {
        const { serverURL, fields, errorServer, sfActive } = this.state;

        return (
            <div className="description">
                <form
                    style={{ width: '100%' }}
                    className="ui form grid"
                    onSubmit={e => this.onDataSubmit(e)}
                >
                    <div className="sixteen wide column field">
                        <label>
                            Server:
                            <input
                                type="text"
                                name="serverURL"
                                placeholder="Server URL"
                                value={serverURL}
                                disabled={!sfActive}
                                onChange={this.onChangeField.bind(this, -1)}
                            />
                        </label>
                        {errorServer && (
                            <span style={{ color: 'red' }}>Server URL is required</span>
                        )}
                    </div>
                    {fields.length > 0 &&
                    fields.map((field, index) => (
                        (field.key && field.value) &&
                        <Fragment key={`keyvalue${index}`}>
                            <div className='six wide column field'>
                                <label key={`labelKey0${index}`} htmlFor='keyUser'>
                                    Key:
                                    <input
                                        id='keyUser'
                                        key={`inputKey1${index}`}
                                        type='text'
                                        name='key'
                                        placeholder='e.g.user'
                                        value={field.key}
                                        disabled={!sfActive}
                                        onChange={this.onChangeField.bind(this, index)}
                                    />
                                </label>
                            </div>
                            <div className='ten wide column field'>
                                <label key={`labelValue0${index}`} htmlFor='valueUser'>
                                    Value:
                                    <input
                                        id='valueUser'
                                        key={`inputValue1${index}`}
                                        type='text'
                                        name='value'
                                        placeholder='e.g.test'
                                        value={field.value}
                                        disabled={!sfActive}
                                        onChange={this.onChangeField.bind(this, index)}
                                    />
                                </label>
                            </div>
                        </Fragment>
                    ))}
                    <div className="description">
                        <button
                            className={`ui ${!sfActive ? '' : 'primary'} button `}
                            type="submit"
                        >
                            {!sfActive ? 'Edit' : 'Update data'}
                        </button>
                        <button
                            className="ui medium button"
                            type="button"
                            onClick={this.deleteTemplate.bind(this)}
                        >
                            Remove connector
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        connectMedicom: state.connectors.medicom
    };
};

const mapDispatchToProps = dispatch => {
    return {
        connectors: bindActionCreators(connectorsAction, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectorPage);
