import { actionTypes } from '../constants/actionTypes';

const INITIAL_STATE = {
  isLoggedIn: false,
  errorMessage: '',
  serverURL: '',
  username: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.auth.LOGIN:
      return {
        ...state,
        errorMessage: ''
      };
    case actionTypes.auth.LOGIN_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        isLoggedIn: true,
        serverURL: action.credentials.serverURL,
        username: action.credentials.username,
        userId: action.credentials.userId
      };
    case actionTypes.auth.LOGIN_FAIL:
      return {
        ...state,
        errorMessage: action.message,
        isLoggedIn: false
      };
    case actionTypes.auth.LOGOUT:
      return {
        ...state,
        isLoggedIn: false
      };
    default:
      return state;
  }
};
