import React, { Fragment } from 'react';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import connectorsAction from '../../actions/connectorsAction';

class URLBuilderPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            serverURL: '',
            fields: [
                {
                    key: '',
                    value: ''
                }
            ],
            templateName: '',
            selectValue: '',
            errorServer: false,
            errorTemplatesName: false
        };

        this.saveAsTemplate = this.saveAsTemplate.bind(this);
    }

    onDataSubmit(event) {
        event.preventDefault();
        const errorServer = this.state.serverURL === '';
        const errorTemplatesName = this.state.errorTemplatesName === '';

        const errorValidation = errorServer || errorTemplatesName;

        !errorValidation && this.saveAsTemplate();
        this.setState({ errorServer });
    }

    resetForm() {
        this.setState({
            serverURL: '',
            fields: [
                {
                    key: '',
                    value: ''
                }
            ],
            templateName: '',
            selectValue: '',
            errorTemplatesName: false,
            errorServer: false,
            errorMessage: ''
        });
    }

    onChangeField(index, event) {
        const currentName = event.target.name;
        const currentValue = event.target.value;

        if (index !== -1) {
            this.setState(state => {
                const fields = state.fields.map((item, j) => {
                    if (j === index) {
                        item[currentName] = currentValue;
                        return item;
                    }
                    return item;
                });
                return {
                    fields,
                    errorTemplatesName: false,
                    errorServer: false
                };
            });
        } else {
            this.setState({
                [event.target.name]: event.target.value.trim()
            });
        }
    }

    addFields() {
        const { fields } = this.state;
        this.setState({
            fields: [
                ...fields,
                {
                    key: '',
                    value: ''
                }
            ]
        });
    }

    saveAsTemplate() {
        const { templateName } = this.state;
        const { templates } = this.props;
        let errorMessage;
        if (templateName) {
            if (templates.map(item => item.key === templateName).includes(true)) {
                errorMessage = 'This connector name already exists';
                this.setState({ errorMessage, errorTemplatesName: true });
            } else {
                this.props.connectors.saveConnector(this.state);
                this.setState({
                    selectValue: templateName,
                    templateName,
                    errorTemplatesName: false
                });
                this.resetForm();
                this.props.closeModal(false);
            }
        } else {
            this.setState({
                errorTemplatesName: true,
                errorMessage: 'Title is required'
            });
        }
    }

    handleCloseModal = () => {
        this.resetForm();
        this.props.closeModal(false);
    };

    render() {
        const { openModal } = this.props;
        const {
            serverURL,
            errorServer,
            fields,
            templateName,
            errorTemplatesName,
            errorMessage
        } = this.state;

        return (
            <Modal
                size="tiny"
                closeIcon
                onClose={this.handleCloseModal}
                open={openModal}
            >
                <Modal.Header>Save connector as</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <form
                            style={{ width: '100%' }}
                            className="ui form grid"
                            onSubmit={e => this.onDataSubmit(e)}
                        >
                            <div className="sixteen wide column field">
                                <label htmlFor="templateName">
                                    Connector name:
                                    <input
                                        id="templateName"
                                        type="text"
                                        name="templateName"
                                        placeholder="Connector name"
                                        value={templateName}
                                        onChange={this.onChangeField.bind(this, -1)}
                                    />
                                </label>
                                {errorTemplatesName && (
                                    <span style={{ color: 'red' }}>{errorMessage}</span>
                                )}
                            </div>
                            <div className="sixteen wide column field">
                                <label htmlFor="serverURL">
                                    Server:
                                    <input
                                        id="serverURL"
                                        type="text"
                                        name="serverURL"
                                        placeholder="Server URL"
                                        value={serverURL}
                                        onChange={this.onChangeField.bind(this, -1)}
                                    />
                                </label>
                                {errorServer && (
                                    <span style={{ color: 'red' }}>Server URL is required</span>
                                )}
                            </div>
                            {fields.length > 0 &&
                            fields.map((field, index) => (
                                <Fragment key={`keyvalue${index}`}>
                                    <div className="four wide column field">
                                        <label key={`labelKey0${index}`} htmlFor="keyUser">
                                            Key:
                                            <input
                                                id="keyUser"
                                                key={`inputKey1${index}`}
                                                type="text"
                                                name="key"
                                                placeholder="e.g.user"
                                                value={field.key}
                                                onChange={this.onChangeField.bind(this, index)}
                                            />
                                        </label>
                                    </div>
                                    <div className="ten wide column field">
                                        <label key={`labelValue0${index}`} htmlFor="valueUser">
                                            Value:
                                            <input
                                                id="valueUser"
                                                key={`inputValue1${index}`}
                                                type="text"
                                                name="value"
                                                placeholder="e.g.test"
                                                value={field.value}
                                                onChange={this.onChangeField.bind(this, index)}
                                            />
                                        </label>
                                    </div>
                                </Fragment>
                            ))}
                            <div style={{ margin: 'auto' }} className="two wide column field">
                                <button
                                    type="button"
                                    onClick={this.addFields.bind(this)}
                                    className="circular ui icon button"
                                >
                                    <i className="icon add" />
                                </button>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    marginBottom: '10px'
                                }}
                            >
                                <button
                                    className="ui medium primary button"
                                    type="submit"
                                    onClick={this.saveAsTemplate}
                                    disabled={!serverURL}
                                >
                                    Save connector
                                </button>
                                <button
                                    className="ui medium button"
                                    type="button"
                                    onClick={this.resetForm.bind(this)}
                                >
                                    Reset form
                                </button>
                            </div>
                        </form>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        templates: state.connectors.templates
    };
};

const mapDispatchToProps = dispatch => {
    return {
        connectors: bindActionCreators(connectorsAction, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(URLBuilderPage);
