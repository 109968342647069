import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import connectorsAction from '../../actions/connectorsAction';

const style4IconEditField = {
    position: 'relative',
    float: 'right',
    top: '-30px',
    right: '10px'
};

class MedicomPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorServer: false,
            errorGpCrmUserId: false,
            changeServerName: false
        };
    }

    componentWillUnmount() {
        !this.props.connectMedicom.gpCrmUserId &&
        this.props.connectors.setMedicomConnection()
    }

    onDataSubmit = (e, sfActive) => {
        e.preventDefault();

        const errorServer = e.target.sfserver.value === '';
        const errorGpCrmUserId = e.target.sfgpCrmUserId.value === '';
        const errorValidation = (
            errorServer ||
            errorGpCrmUserId);

        sfActive && !errorValidation ?
            this.props.connectors.setMedicomSettings(
                e.target.sfserver.value,
                e.target.sfgpCrmUserId.value
            )
            : this.props.connectors.clearMedicomSettings();

        this.setState({ errorServer });
        this.setState({ errorGpCrmUserId });
        this.setState({ changeServerName: false });
    };

    onChangeServerName(e) {
        const { changeServerName } = this.state;
        console.log('CHANGE value');
        this.setState({
            changeServerName: !changeServerName
        });
    }

    deleteTemplate() {
        this.props.connectors.clearMedicomSettings();
    }

    render() {
        const { changeServerName } = this.state;
        const sfActive = !this.props.connectMedicom.gpCrmUserId;

        const iconEditServerName = changeServerName
            ? 'check link icon'
            : 'edit link icon';

        return (
            <div className='description'>
                <form
                    className='ui form'
                    onSubmit={e => this.onDataSubmit(e, sfActive)}
                >
                    <div className='field'>
                        <label>Server:</label>
                        <input
                            type='text'
                            name='sfserver'
                            placeholder='https://ucm-proxy.axeos.nl'
                            defaultValue={
                                this.props.connectMedicom.serverURL
                                    ? this.props.connectMedicom.serverURL
                                    : ''
                            }
                            disabled={!changeServerName}
                        />
                        {sfActive && <i
                            aria-hidden="true"
                            style={style4IconEditField}
                            className={iconEditServerName}
                            onClick={this.onChangeServerName.bind(this)}
                        />}
                        {this.state.errorServer && <span style={{ color: 'red' }}>Server URL is required</span>}
                    </div>
                    <div className='field'>
                        <label>User ID Medicom:</label>
                        <input
                            type='text'
                            name='sfgpCrmUserId'
                            placeholder='User ID Medicom'
                            defaultValue={
                                this.props.connectMedicom.gpCrmUserId
                                    ? this.props.connectMedicom.gpCrmUserId
                                    : ''
                            }
                            disabled={!!this.props.connectMedicom.gpCrmUserId}
                        />
                        {this.state.errorGpCrmUserId && <span style={{ color: 'red' }}>User ID is required</span>}
                    </div>
                    <button
                        className={`ui ${
                            !sfActive ? '' : 'primary'
                        } button `}
                        type='submit'
                    >
                        {!sfActive ? 'Edit' : 'Set Medicom data'}
                    </button>
                    <button
                        className="ui medium button"
                        type="reset"
                        onClick={this.deleteTemplate.bind(this)}
                    >
                        Remove
                    </button>
                </form>
                <div className="ui divider" />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        connectMedicom: state.connectors.medicom,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        connectors: bindActionCreators(connectorsAction, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MedicomPage);
