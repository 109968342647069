const actionTypes = {
  auth: {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL'
  },

  switch: {
    FETCH_USER_SWITCHES: 'FETCH_USER_SWITCHES',
    SET_USER_SWITCHES: 'SET_USER_SWITCHES'
  },

  settings: {
    SET_PRIMARY_PHONE: 'SET_PRIMARY_PHONE',
    SET_AUTO_ANSWER: 'SET_AUTO_ANSWER',
    SET_DIAL_PREFIX: 'SET_DIAL_PREFIX',
    FETCH_USERSETTINGS_STATUS: 'FETCH_USERSETTINGS_STATUS',
    UPDATE_USERSETTINGS_STATUS: 'UPDATE_USERSETTINGS_STATUS',
    SET_API_CONNECTION_SEQ: 'SET_API_CONNECTION_SEQ',
    SET_STATS_CONNECTION: 'SET_STATS_CONNECTION',
    SET_ACD_TOOL: 'SET_ACD_TOOL',
    SET_CRM_CONNECTOR: 'SET_CRM_CONNECTOR',
    SET_DEFAULT_QUEUE_VALUE: 'SET_DEFAULT_QUEUE_VALUE',
    FORWARD_CALLS_TO_MEDICOM: 'FORWARD_CALLS_TO_MEDICOM'
  },

  phones: {
    FETCH_PHONES: 'FETCH_PHONES',
    FETCH_PHONE_BY_ID: 'FETCH_PHONE_BY_ID'
  },

  user: {
    FETCH_USERS: 'FETCH_USERS',
    FETCH_USER: 'FETCH_USER',
    FETCH_USER_STATUS: 'FETCH_USER_STATUS',
    EVENT_LISTENING: 'EVENT_LISTENING'
  },

  stats: {
    FETCH_STATS: 'FETCH_STATS',
    UPDATE_STATS: 'UPDATE_STATS',
  },

  connectors: {
    FETCH_SALESFORCE: 'FETCH_SALESFORCE',
    SET_SALESFORCE_ENABLED: 'SET_SALESFORCE_ENABLED',
    SET_SALESFORCE_SETTING: 'SET_SALESFORCE_SETTING',
    FETCH_MEDICOM: 'FETCH_MEDICOM',
    SET_MEDICOM_ENABLED: 'SET_MEDICOM_ENABLED',
    SET_MEDICOM_SETTING: 'SET_MEDICOM_SETTING',
    SAVE_CONNECTOR: 'SAVE_CONNECTOR',
    UPDATE_CONNECTOR: 'UPDATE_CONNECTOR',
    UPDATE_ENABLE_CONNECTOR: 'UPDATE_ENABLE_CONNECTOR',
    REMOVE_CONNECTOR: 'REMOVE_CONNECTOR'
  }
};

export { actionTypes };
