import { actionTypes } from '../constants/actionTypes';

export default (state = [], action) => {
  switch (action.type) {
    case actionTypes.switch.FETCH_USER_SWITCHES:
      return action.payload;
    case actionTypes.switch.SET_USER_SWITCHES:
      let newState = [...state];
      newState.map(item => {
        if (item.uuid === action.payload.uuid) {
          item.value = action.payload.value;
        }
        return null
      });
      return newState;
    default:
      return state;
  }
};
