import React from 'react';
import * as PropTypes from 'prop-types';

class SwitchesTable extends React.Component {
  render() {
    return (
      <table className="ui very basic unstackable table">
        <thead>
          <tr>
            <th className="four wide">Status</th>
            <th className="twelve wide">Name</th>
          </tr>
        </thead>
        <tbody>
          {this.props.userSwitches.map(userSwitch => {
            return (
              <tr key={userSwitch.uuid}>
                <td>
                  <div className="ui toggle checkbox">
                    <input
                      type="checkbox"
                      name={`switch-${userSwitch.uuid}`}
                      checked={userSwitch.value}
                      onChange={e =>
                        this.props.onToggleChange(
                          userSwitch.uuid,
                          e.target.checked
                        )
                      }
                    />
                    <label />
                  </div>
                </td>
                <td>
                  {userSwitch.name.length > 56
                    ? `${userSwitch.name.toString().substr(0, 53)}...`
                    : userSwitch.name}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

SwitchesTable.propTypes = {
  onToggleChange: PropTypes.func.isRequired
};

export default SwitchesTable;
