import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CryptoService from '../../services/cryptoService';
import connectorsAction from '../../actions/connectorsAction';

const style4IconVisibleField = {
    position: 'relative',
    float: 'right',
    top: '-30px',
    right: '10px'
};

class SalesForcePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visiblePassword: false,
            visibleConsumerKey: false,
            visibleConsumerSecret: false,
            visibleSecurityToken: false,
            errorServer: false,
            errorUsername: false,
            errorPassword: false,
            errorConsumerKey: false,
            errorConsumerSecret: false,
            errorValidation: false
        };
    }

    componentWillUnmount() {
        !this.props.connectSalesforce.serverURL &&
        this.props.connectors.setSalesforceConnection();
    }

    onSalesForceDataSubmit = (e, sfActive) => {
        e.preventDefault();

        const errorServer = e.target.sfserver.value === '';
        const errorUsername = e.target.sfusername.value === '';
        const errorPassword = e.target.sfpassword.value === '';
        const errorConsumerKey = e.target.sfconsumerKey.value === '';
        const errorConsumerSecret = e.target.sfconsumerSecret.value === '';
        const errorValidation = (
            errorServer ||
            errorUsername ||
            errorPassword ||
            errorConsumerKey ||
            errorConsumerSecret);

        sfActive && !errorValidation ?
            this.props.connectors.setSalesforceSettings(
                e.target.sfserver.value,
                e.target.sfusername.value,
                e.target.sfpassword.value,
                e.target.sfconsumerKey.value,
                e.target.sfconsumerSecret.value,
                e.target.sfsecurityToken.value
            )
            : this.props.connectors.clearSalesforceSettings();

        this.setState({ errorServer });
        this.setState({ errorUsername });
        this.setState({ errorPassword });
        this.setState({ errorConsumerKey });
        this.setState({ errorConsumerSecret });
        this.setState({ errorValidation });
        this.setState({
            visiblePassword: false,
            visibleConsumerKey: false,
            visibleConsumerSecret: false,
            visibleSecurityToken: false
        });
    };

    onHandleVisibleField = (key, event) => {
        let visible = this.state[key];
        this.setState({
            [key]: !visible
        });
    };

    deleteTemplate() {
        this.props.connectors.clearSalesforceSettings();
    }

    render() {
        const {
            visiblePassword,
            visibleConsumerKey,
            visibleConsumerSecret,
            visibleSecurityToken
        } = this.state;

        const sfActive = !(
            this.props.connectSalesforce.serverURL ||
            this.props.connectSalesforce.username ||
            this.props.connectSalesforce.password ||
            this.props.connectSalesforce.consumerKey ||
            this.props.connectSalesforce.consumerSecret);

        const iconVisiblePassword = visiblePassword ? 'eye slash link icon' : 'eye link icon';
        const iconVisibleConsumerKey = visibleConsumerKey ? 'eye slash link icon' : 'eye link icon';
        const iconVisibleConsumerSecret = visibleConsumerSecret ? 'eye slash link icon' : 'eye link icon';
        const iconVisibleSecurityToken = visibleSecurityToken ? 'eye slash link icon' : 'eye link icon';
        const saveButtonClassName = `ui ${!sfActive ? '' : 'primary'} button `;
        const saveButtonTitle = !sfActive ? 'Edit' : 'Set SalesForce data';

        return (
            <div className='description'>
                <form
                    className='ui form'
                    onSubmit={e => this.onSalesForceDataSubmit(e, sfActive)}
                >
                    <div className='field'>
                        <label>Server:</label>
                        <input
                            type='text'
                            name='sfserver'
                            placeholder='Server URL'
                            defaultValue={
                                this.props.connectSalesforce.serverURL
                                    ? this.props.connectSalesforce.serverURL
                                    : ''
                            }
                            disabled={!!this.props.connectSalesforce.serverURL}
                        />
                        {this.state.errorServer && <span style={{ color: 'red' }}>Server URL is required</span>}
                    </div>
                    <div className='field'>
                        <label>Username:</label>
                        <input
                            type='text'
                            name='sfusername'
                            placeholder='User name'
                            defaultValue={
                                this.props.connectSalesforce.username
                                    ? this.props.connectSalesforce.username
                                    : ''
                            }
                            disabled={!!this.props.connectSalesforce.serverURL}
                        />
                        {this.state.errorUsername && <span style={{ color: 'red' }}>Username is required</span>}
                    </div>
                    <div className='field'>
                        <label>Password:</label>
                        <input
                            type={visiblePassword ? 'text' : 'password'}
                            name='sfpassword'
                            placeholder='Password'
                            defaultValue={
                                this.props.connectSalesforce.password
                                    ? CryptoService.decryptString(this.props.connectSalesforce.password)
                                    : ''
                            }
                            disabled={!!this.props.connectSalesforce.serverURL}
                        />
                        {sfActive && <i
                            aria-hidden='true'
                            style={style4IconVisibleField}
                            className={iconVisiblePassword}
                            onClick={this.onHandleVisibleField.bind(this, 'visiblePassword')}/>}
                        {this.state.errorPassword && <span style={{ color: 'red' }}>Password is required</span>}
                    </div>
                    <div className='field'>
                        <label>Consumer Key:</label>
                        <input
                            type={visibleConsumerKey ? 'text' : 'password'}
                            name='sfconsumerKey'
                            placeholder='Consumer Key'
                            defaultValue={
                                this.props.connectSalesforce.consumerKey
                                    ? CryptoService.decryptString(this.props.connectSalesforce.consumerKey)
                                    : ''
                            }
                            disabled={!!this.props.connectSalesforce.serverURL}
                        />
                        {sfActive && <i
                            aria-hidden='true'
                            style={style4IconVisibleField}
                            className={iconVisibleConsumerKey}
                            onClick={this.onHandleVisibleField.bind(this, 'visibleConsumerKey')}/>}
                        {this.state.errorConsumerKey && <span style={{ color: 'red' }}>Consumer Key is required</span>}
                    </div>
                    <div className='field'>
                        <label>Consumer Secret:</label>
                        <input
                            type={visibleConsumerSecret ? 'text' : 'password'}
                            name='sfconsumerSecret'
                            placeholder='Consumer Secret'
                            defaultValue={
                                this.props.connectSalesforce.consumerSecret
                                    ? CryptoService.decryptString(this.props.connectSalesforce.consumerSecret)
                                    : ''
                            }
                            disabled={!!this.props.connectSalesforce.serverURL}
                        />
                        {sfActive && <i
                            aria-hidden='true'
                            style={style4IconVisibleField}
                            className={iconVisibleConsumerSecret}
                            onClick={this.onHandleVisibleField.bind(this, 'visibleConsumerSecret')}/>}
                        {this.state.errorConsumerSecret && <span style={{ color: 'red' }}>Consumer Secret is required</span>}
                    </div>
                    <div className='field'>
                        <label>Security Token (optional):</label>
                        <input
                            type={visibleSecurityToken ? 'text' : 'password'}
                            name='sfsecurityToken'
                            placeholder='Security token'
                            defaultValue={
                                this.props.connectSalesforce.securityToken
                                    ? CryptoService.decryptString(this.props.connectSalesforce.securityToken)
                                    : ''
                            }
                            disabled={!!this.props.connectSalesforce.serverURL} />
                        {sfActive && <i
                            aria-hidden='true'
                            style={style4IconVisibleField}
                            className={iconVisibleSecurityToken}
                            onClick={this.onHandleVisibleField.bind(this, 'visibleSecurityToken')}/>}
                    </div>
                    <button
                        className={saveButtonClassName}
                        type='submit'
                    >
                        {saveButtonTitle}
                    </button>
                    <button
                        className="ui medium button"
                        type="reset"
                        onClick={this.deleteTemplate.bind(this)}
                    >
                        Remove
                    </button>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        connectSalesforce: state.connectors.salesforce,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        connectors: bindActionCreators(connectorsAction, dispatch)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesForcePage);
