import React from 'react';
import { render } from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import App from './containers/App';
import { configureStore, history } from './store/configureStore';
import { Provider } from 'react-redux';
import { init } from './main';
import './index.css';

const store = configureStore();

/* init permissions for notification, add to home screen, service worker */
init();

render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);
