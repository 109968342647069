import ApiService from '../services/apiService';
import { apiRoutes } from '../constants/apiRoutes';
import { actionTypes } from '../constants/actionTypes';

const fetchStats = () => {
  return async dispatch => {
    const response = await ApiService.performRequest(
      'POST',
      apiRoutes.stats.get,
      { relative: true }
    );
    dispatch({
      type: actionTypes.stats.FETCH_STATS,
      payload: response.data
    });
  };
};

const updateStats = stats => {
  return async dispatch => {
    dispatch({
      type: actionTypes.stats.UPDATE_STATS,
      payload: stats
    });
  };
};

export default {
  fetchStats,
  updateStats,
};
