import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createHashHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from '../reducers';
import {actionTypes} from "../constants/actionTypes";
import {PROXY_SERVER} from "../constants/mainConst";

const history = createHashHistory();

const mainReducer = createRootReducer(history);

const rootReducer = (state, action) => {
  if (action.type === actionTypes.auth.LOGOUT) {
    state = {};
  }

  return mainReducer(state, action);
};

const router = routerMiddleware(history);
const enhancer = applyMiddleware(thunk, router);

export default { configureStore, history };

let connectors = {
  salesforce: {enabled: false},
  medicom: {
    enabled: false,
    serverURL: PROXY_SERVER
  },
  templates: []
};

function configureStore(initialState?: {}) {

  let forwardMedicom = false;
  if (window.localStorage.getItem('crm-config')) {
    connectors = JSON.parse(window.localStorage.getItem('crm-config'));
    if (window.localStorage.getItem('crm-config')['medicom']) {
      forwardMedicom = JSON.parse(window.localStorage.getItem('crm-config'))['medicom']['forwardAll'];
    }
  } else {
    window.localStorage.setItem('crm-config', JSON.stringify(connectors))
  }

  initialState = {
    settings: {
      primaryPhone: window.localStorage.getItem('primaryPhone') || {},
      autoAnswer: window.localStorage.getItem('autoAnswer') || {},
      dialPrefix: window.localStorage.getItem('dialPrefix') || {},
      seq: window.localStorage.getItem('lastSeq') || 0,
      dnd: {},
      hot_desk: {},
      forwardMedicom
    },
    user: [],
    phones: [],
    userSwitches: [],
    connectors
  };

  return createStore(rootReducer, initialState, enhancer);
}
