import {actionTypes} from '../constants/actionTypes';
import {PROXY_SERVER} from "../constants/mainConst";

const INITIAL_STATE = {
  salesforce: {
    enabled: false,
  },
  medicom: {
    serverURL: PROXY_SERVER,
    enabled: false
  },
  templates: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.connectors.FETCH_SALESFORCE:
      return {
        ...state,
        salesforce: action.payload
      };
    case actionTypes.connectors.SET_SALESFORCE_ENABLED:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          enabled: action.payload
        }
      };
    case actionTypes.connectors.SET_SALESFORCE_SETTING:
      return {
        ...state,
        salesforce: action.payload
      };
    case actionTypes.connectors.FETCH_MEDICOM:
      return {
        ...state,
        medicom: action.payload
      };
    case actionTypes.connectors.SET_MEDICOM_ENABLED:
      return {
        ...state,
        medicom: {
          ...state.medicom,
          enabled: action.payload
        }
      };
    case actionTypes.connectors.SET_MEDICOM_SETTING:
      return {
        ...state,
        medicom: action.payload
      };
    case actionTypes.connectors.SAVE_CONNECTOR:
      return {
        ...state,
        templates: [...state.templates, action.payload]
      };
    case actionTypes.connectors.UPDATE_CONNECTOR:
      return {
        ...state,
        templates: action.payload
      };
    case actionTypes.connectors.UPDATE_ENABLE_CONNECTOR:
      return {
        ...state,
        templates: action.payload
      };
    case actionTypes.connectors.REMOVE_CONNECTOR:
      return {
        ...state,
        templates: action.payload
      };
    default:
      return state;
  }
};
