import ApiService from '../services/apiService';
import { apiRoutes } from '../constants/apiRoutes';
import { actionTypes } from '../constants/actionTypes';

const fetchUserSwitches = () => {
  return async dispatch => {
    const response = await ApiService.performRequest(
      'GET',
      apiRoutes.switch.list
    );

    dispatch({
      type: actionTypes.switch.FETCH_USER_SWITCHES,
      payload: response.data
    });
  };
};

const setUserSwitch = (uuid, value) => {
  return async dispatch => {
    let url = apiRoutes.switch.post.replace('{uuid}', uuid);

    const response = await ApiService.performRequest('POST', url, {
      value: value
    });

    dispatch({
      type: actionTypes.switch.SET_USER_SWITCHES,
      payload: response.data
    });
  };
};

export default {
  fetchUserSwitches,
  setUserSwitch
};
