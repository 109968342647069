// @flow
import React from 'react';
import { connect } from 'react-redux';

import settingsActions from '../../actions/settingsActions';
import userActions from '../../actions/userActions';
import phonesActions from '../../actions/phonesActions';
import { bindActionCreators } from 'redux';

class PhonesPage extends React.Component {
  componentDidMount() {
    this.props.userActions.fetchUserByIdWithStatus();
    this.props.phonesActions.fetchPhones();
  }

  getCurrentPrimaryPhone = () => {
    let isCurrentPrimaryPhoneOnTheList = true;

    if (
      this.props.userPhones != null &&
      this.props.settings.primaryPhone.uuid != null
    ) {
      let i = 0;
      for (i = 0; i < this.props.userPhones.length; i += 1) {
        if (
          this.props.userPhones[i].uuid ===
          this.props.settings.primaryPhone.uuid
        ) {
          isCurrentPrimaryPhoneOnTheList = true;
          break;
        }
      }

      if (!isCurrentPrimaryPhoneOnTheList) {
        this.props.settingsActions.setPrimaryPhone(null, null);
      }
    }

    if (this.props.settings.primaryPhone.name != null) {
      const primaryPhone =
        this.props.settings.primaryPhone.name.length > 47
          ? `${this.props.settings.primaryPhone.name
              .toString()
              .substr(0, 44)}...`
          : this.props.settings.primaryPhone.name;

      return primaryPhone;
    }

    return 'not selected';
  };

  phoneType = phone => {
    let phoneType = '';
    if (
      this.props.user.hot_desk &&
      this.props.user.hot_desk === phone.uuid
    ) {
      phoneType = 'Hotdesk';
    } else if (phone.type === 'Axeos Phone') {
      phoneType = phone.type;
    } else if (this.props.userPhones && this.props.userPhonesById) {
      this.props.userPhonesById.map(userPhone => {
        if (phone.uuid === userPhone.phone) {
          phoneType = userPhone.personal ? 'Personal' : 'Non-personal';
        }
        return true;
      });
    }
    return phoneType;
  };

  onRadioCheck = phone => {
    const name = phone.name ? phone.name : `${phone.type} (${phone.phone_username})`;
    this.props.settingsActions.setPrimaryPhone(name, phone.uuid);
  };

  render() {
    return (
      <div>
        <div className="ui padded basic segment">
          <h2 className="ui header">
            <i className="clockwise rotated phone icon" />
            <div className="content">Phones</div>
          </h2>
        </div>
        <div className="ui secondary segment grid container">
          <div className="row">
            <div className="four wide column">Primary phone:</div>
            <div className="ui small header ten wide column">
              {this.getCurrentPrimaryPhone()}
            </div>
          </div>
        </div>
        <div className="ui basic segment">
          <table className="ui very basic unstackable table">
            <thead>
              <tr>
                <th className="seven wide">Name</th>
                <th className="four wide">Type</th>
                <th className="three wide">Primary phone</th>
              </tr>
            </thead>
            <tbody>
              {this.props.userPhones.map(phone => {
                return (
                  <tr key={phone.uuid}>
                    <td>
                      {phone.name != null && phone.name !== ''
                        ? phone.name.length > 30
                          ? `${phone.name.toString().substr(0, 27)}...`
                          : phone.name
                        : `${phone.type} (${phone.phone_username})`}
                    </td>
                    <td>{this.phoneType(phone)}</td>
                    <td className="center aligned">
                      <div
                        className="ui radio checkbox"
                        style={{ color: 'red' }}
                      >
                        <input
                          type="radio"
                          name="radio"
                          id={phone.uuid}
                          disabled={this.phoneType(phone) === 'Non-personal'}
                          checked={
                            this.props.settings.primaryPhone.uuid === phone.uuid
                          }
                          onChange={() => this.onRadioCheck(phone)}
                        />
                        <label htmlFor={phone.uuid} />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    settings: state.settings,
    user: state.user,
    userPhones: state.phones,
    userPhonesById: state.user.phones
  };
};

const mapDispatchToProps = dispatch => {
  return {
    settingsActions: bindActionCreators(settingsActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    phonesActions: bindActionCreators(phonesActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhonesPage);
