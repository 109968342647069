// @flow
import React from 'react';
import SwitchesTable from '../../components/SwitchesTable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import userSwitchesActions from '../../actions/userSwitchesActions';

class SwitchesPage extends React.Component {
  componentDidMount() {
    this.props.userSwitchesActions.fetchUserSwitches();
  }

  onToggleChange = (uuid, checked) => {
    this.props.userSwitchesActions.setUserSwitch(uuid, checked);
  };

  render() {
    return (
      <div>
        <div className="ui padded basic segment">
          <h2 className="ui header">
            <i className="toggle on icon" />
            <div className="content">Switches</div>
          </h2>
        </div>
        <div className="ui basic segment">
          <SwitchesTable
            userSwitches={this.props.userSwitches}
            onToggleChange={this.onToggleChange}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userSwitches: state.userSwitches
  };
};

const mapDispatchToProps = dispatch => {
  return {
    userSwitchesActions: bindActionCreators(userSwitchesActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SwitchesPage);
