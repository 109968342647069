import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import thunk from 'redux-thunk';
import { createHashHistory } from 'history';
import { routerMiddleware, routerActions } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import createRootReducer from '../reducers';

import { actionTypes } from '../constants/actionTypes';

import {PROXY_SERVER} from "../constants/mainConst";

const history = createHashHistory();

const mainReducer = createRootReducer(history);

const rootReducer = (state, action) => {
  if (action.type === actionTypes.auth.LOGOUT) {
    state = {};
  }

  return mainReducer(state, action);
};

const configureStore = (initialState?: localData) => {
  // Redux Configuration
  const middleware = [];
  const enhancers = [];

  // Thunk Middleware
  middleware.push(thunk);

  // Logging Middleware
  const logger = createLogger({
    level: 'info',
    collapsed: true
  });

  // Skip redux logs in console during the tests
  if (process.env.NODE_ENV !== 'test') {
    middleware.push(logger);
  }

  // Router Middleware
  const router = routerMiddleware(history);
  middleware.push(router);

  // Redux DevTools Configuration
  const actionCreators = {
    ...routerActions
  };
  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION
      ? window.__REDUX_DEVTOOLS_EXTENSION({
          // Options: http://extension.remotedev.io/docs/API/Arguments.html
          actionCreators
        })
      : compose;

  // Apply Middleware & Compose Enhancers
  enhancers.push(composeWithDevTools(applyMiddleware(...middleware)));
  const enhancer = composeEnhancers(...enhancers);

  let connectors = {
    salesforce: {enabled: false},
    medicom: {
      enabled: false,
      serverURL: PROXY_SERVER
    },
    templates: []
  };

  let forwardMedicom = false;
  if (window.localStorage.getItem('crm-config')) {
    connectors = JSON.parse(window.localStorage.getItem('crm-config'));
    if (window.localStorage.getItem('crm-config')['medicom']) {
      forwardMedicom = JSON.parse(window.localStorage.getItem('crm-config'))['medicom']['forwardAll'];
    }
  } else {
    window.localStorage.setItem('crm-config', JSON.stringify(connectors))
  }

  initialState = {
    settings: {
      primaryPhone: window.localStorage.getItem('primaryPhone') || {},
      autoAnswer: window.localStorage.getItem('autoAnswer') || {},
      dialPrefix: window.localStorage.getItem('dialPrefix') || {},
      seq: window.localStorage.getItem('lastSeq') || 0,
      dnd: {},
      hot_desk: {},
      forwardMedicom
    },
    user: [],
    phones: [],
    userSwitches: [],
    connectors
  };

  // Create Store
  const store = createStore(rootReducer, initialState, enhancer);

  if (module.hot) {
    module.hot.accept(
      '../reducers',
      // eslint-disable-next-line global-require
      () => store.replaceReducer(require('../reducers').default)
    );
  }
  return store;
}

export default { configureStore, history };
