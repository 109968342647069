import PageLayout from '../containers/layout/PageLayout';
import WindowLayout from '../containers/layout/WindowLayout';

const windowRoutes = [
  {
    name: 'Pages',
    path: '/',
    exact: false,
    component: PageLayout
  },
  {
    name: 'Windows',
    path: '/windows',
    exact: false,
    component: WindowLayout
  }
];

export default windowRoutes;
