import React from 'react';
import { connect } from 'react-redux';
import authenticationActions from '../../actions/authenticationActions';
import { bindActionCreators } from 'redux';
import { callFromClipboard, isContentValidAxeosNumber } from '../../helpers/click2dial';
import { Icon, Input, Popup, Label } from "semantic-ui-react";

class Logout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            number: '',
            phoneNumberError: false
        }
    }

    logOut = () => {
    this.props.authenticationActions.logOut();
  };

    onPhoneNumber (event){
        this.setState({
            phoneNumberError: false,
            number: event.target.value
        })
    }

    onCall(event) {
        let validNumber = isContentValidAxeosNumber(this.state.number);
        if (validNumber) {
            let numberToCall = validNumber[0]
                .toString()
                .split('(')
                .join('')
                .split(')')
                .join('')
                .split('-')
                .join('')
                .split(/\s+/)
                .join('')
                .split('%20')
                .join('')
                .split('axeostel://')
                .join('')
                .replace('+', '00');
            if (numberToCall % 1 === 0) {
                callFromClipboard(numberToCall);
            }
            this.setState({
                visibleInput: false
            })
        } else {
            this.setState({
                phoneNumberError: true
            })
        }
    }

    render() {
        return (
            <div className="ui floated right tabular icon menu">
                <div className="item">
                    <strong>{`${
                        this.props.authentication.username
                    }@${this.props.authentication.serverURL.replace(
                        'https://',
                        ''
                    )}`}</strong>
                </div>
                <button className="item">
                    <Popup trigger={<i className="call icon"/>} on='click'>
                        <Input
                            error={this.state.phoneNumberError}
                            icon={<Icon name='call' inverted circular color="orange" link onClick={this.onCall.bind(this)}/>}
                            focus
                            placeholder='Call...'
                            onChange={this.onPhoneNumber.bind(this)}/>
                        {this.state.phoneNumberError && <Label pointing>Please enter a correct phone number</Label>}
                    </Popup>
                </button>
                <button className="item" onClick={this.logOut}>
                    <i className="sign-out icon"/>
                </button>
            </div>
        );
    }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication
  };
};

const mapDispatchToProps = dispatch => {
  return {
    authenticationActions: bindActionCreators(authenticationActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
