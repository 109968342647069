import ApiService from '../services/apiService';
import { apiRoutes } from '../constants/apiRoutes';
import { actionTypes } from '../constants/actionTypes';
import settingsActions from './settingsActions';

const fetchPhones = () => {
  return async (dispatch, getState) => {
    const { primaryPhone } = getState().settings;

    const response = await ApiService.performRequest(
      'GET',
      apiRoutes.phones.list
    );

    const checkPrimaryPhone = response.data.filter( phone => primaryPhone.uuid === phone.uuid );

    if (checkPrimaryPhone.length === 0) {
      dispatch(settingsActions.setPrimaryPhone(null, null));
    }

    dispatch({
      type: actionTypes.phones.FETCH_PHONES,
      payload: response.data
    });
  };
};

const fetchPhoneById = uuid => {
  return async (dispatch) => {
    let url = apiRoutes.phones.get.replace('{uuid}', uuid);
    const response = await ApiService.performRequest('GET', url);

    dispatch({
      type: actionTypes.phones.FETCH_PHONE_BY_ID,
      payload: response.data.phones
    });
  };
};

export default {
  fetchPhoneById,
  fetchPhones
};
