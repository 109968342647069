import SettingsPage from '../containers/settings/SettingsPage';
import PhonesPage from '../containers/phones/PhonesPage';
import SwitchesPage from '../containers/switches/SwitchesPage';
import ConnectorsTab from '../containers/connectors/ConnectorsTab';
import PluginsPage from '../containers/plugins/PluginsPage';
import LoginForm from '../containers/layout/LoginForm';
import AcdSettings from '../containers/acdSettings/AcdSettings';

const pageRoutes = [
  {
    name: 'SettingsPage',
    path: '/',
    exact: true,
    component: SettingsPage
  },
  {
    name: 'SettingsPage',
    path: '/settings',
    exact: true,
    component: SettingsPage
  },
  {
    name: 'PhonePage',
    path: '/phones',
    exact: true,
    component: PhonesPage
  },
  {
    name: 'SwitchPage',
    path: '/switches',
    exact: true,
    component: SwitchesPage
  },
  {
    name: 'ConnectorsTab',
    path: '/connectorsTab',
    exact: true,
    component: ConnectorsTab
  },
  {
    name: 'PluginPage',
    path: '/plugins',
    exact: true,
    component: PluginsPage
  },
  {
    name: 'LoginForm',
    path: '/login',
    exact: true,
    component: LoginForm
  },
  {
    name: 'ACDSettings',
    path: '/acdSettings',
    exact: true,
    component: AcdSettings
  }
];

export default pageRoutes;
