import { actionTypes } from '../constants/actionTypes';

const INITIAL_STATE = {
  queues: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.stats.FETCH_STATS:
      return {
        ...state,
        queues: action.payload.queues
      };
    case actionTypes.stats.UPDATE_STATS:
      return {
        ...state,
        queues: action.payload.queues
      };
    default:
      return state;
  }
};
