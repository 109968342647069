import ApiService from '../services/apiService';
import { apiRoutes } from '../constants/apiRoutes';
import { actionTypes } from '../constants/actionTypes';
import { DEFAULT_QUEUE_VALUE } from '../constants/mainConst';
import acdStatsActions from "./acdStatsActions";
import userActions from "./userActions";
import checkThresholds from "../helpers/ACDHelper";

const setPrimaryPhone = (name, uuid) => {
  window.localStorage.setItem('primaryPhone', JSON.stringify({ name, uuid }));

  return {
    type: actionTypes.settings.SET_PRIMARY_PHONE,
    payload: {
      name,
      uuid
    }
  };
};

const setAutoAnswer = (enabled = false) => {
  window.localStorage.setItem('autoAnswer', JSON.stringify({ enabled }));

  return {
    type: actionTypes.settings.SET_AUTO_ANSWER,
    payload: {
      enabled
    }
  };
};

const setDialPrefix = (number = null) => {
  window.localStorage.setItem('dialPrefix', JSON.stringify({ number }));

  return {
    type: actionTypes.settings.SET_DIAL_PREFIX,
    payload: {
      number
    }
  };
};

const setCallForwarding = (number = null) => {
  return async (dispatch, getState) => {
    const { userId } = getState().authentication;
    let url = apiRoutes.users.status.replace('{uuid}', userId);

    const response = await ApiService.performRequest('POST', url, {
      call_forward: number
    });

    dispatch({
      type: actionTypes.settings.UPDATE_USERSETTINGS_STATUS,
      payload: response.data
    });
  };
};

const setDndStatus = value => {
  return async (dispatch, getState) => {
    const { userId } = getState().authentication;

    let url = apiRoutes.users.status.replace('{uuid}', userId);

    const response = await ApiService.performRequest('POST', url, {
      dnd: value
    });

    dispatch({
      type: actionTypes.settings.UPDATE_USERSETTINGS_STATUS,
      payload: response.data
    });
  };
};

const fetchUserStatusSettings = () => {
  return async (dispatch, getState) => {
    const { userId } = getState().authentication;
    let url = apiRoutes.users.status.replace('{uuid}', userId);

    const response = await ApiService.performRequest('GET', url);

    //check personal for primary phone
    const { primaryPhone } = getState().settings;
    if (primaryPhone.uuid){
      let checkPrimaryPhones = response.data.phones.filter(phone => primaryPhone.uuid === phone.phone);
      if (checkPrimaryPhones.length && !checkPrimaryPhones[0].personal){
        dispatch(setPrimaryPhone(null, null))
      }
    }

    let currentCallForwardNumber = response.data.call_forward;
    if (currentCallForwardNumber) {
      if (!currentCallForwardNumber.match(/^[0-9+)(*_\-\s]*$/)) {
        response.data.call_forward = null;
      }
    }

    dispatch(userActions.fetchUsers())

    dispatch({
      type: actionTypes.user.FETCH_USER_STATUS,
      payload: response.data
    });

    dispatch({
      type: actionTypes.settings.FETCH_USERSETTINGS_STATUS,
      payload: response.data
    });
  };
};

const setApiConnectionSeq = () => {
  return async dispatch => {
    const response = await ApiService.performRequest(
      'GET',
      apiRoutes.events.get
    );
    const seq = response.data.length && response.data[response.data.length - 1].seg
      ? response.data[response.data.length - 1].seq
      : 0;

    window.localStorage.setItem('lastSeq', seq);

    dispatch({
      type: actionTypes.settings.SET_API_CONNECTION_SEQ,
      payload: seq
    });
  };
};

const setACDConnection = () => {
  return async dispatch => {
    let timer;
    let isLoggedIn = JSON.parse(window.localStorage.getItem('currentUser'))['isLoggedIn'] || false;
    let acd_tool = JSON.parse(window.localStorage.getItem('acd_tool')) || false;

    const acdStats = async () => {
      let acdStatsResponse;
      if (window.localStorage.length > 1){
        acdStatsResponse = await ApiService.performRequest(
            'POST',
            apiRoutes.stats.get,
            {relative: true}
        );

        checkThresholds(acdStatsResponse.data.queues);

        dispatch(acdStatsActions.updateStats(acdStatsResponse.data))

        clearTimeout(timer);
        timer = setTimeout(acdStats, 2000);
      } else {
        clearTimeout(timer)
      }
    }

    isLoggedIn && acd_tool && await acdStats();

    dispatch({
      type: actionTypes.settings.SET_STATS_CONNECTION
    });
  };
};

const setACDTool = enabled => {
  return dispatch => {
    window.localStorage.setItem('acd_tool', enabled);

    dispatch({
      type: actionTypes.settings.SET_ACD_TOOL,
      payload: enabled
    });
  };
};

const setCRMConnector = enabled => {
  return dispatch => {
    window.localStorage.setItem('crm-connector', enabled);

    dispatch({
      type: actionTypes.settings.SET_CRM_CONNECTOR,
      payload: enabled
    });
  };
};

const setDefaultValueQueueValue = () => {
  return async dispatch => {
    const acdStatsResponse = await ApiService.performRequest(
      'POST',
      apiRoutes.stats.get,
      { relative: true }
    );

    //initial queue values local storage
    const localQueuesValues = JSON.parse(window.localStorage.getItem('acdQueues')) || '';
    let queueArray = acdStatsResponse.data.queues;
    let value = {};
    queueArray.map( queue => {
      if (!localQueuesValues.hasOwnProperty(queue.uuid)) {
        value[queue.uuid] = DEFAULT_QUEUE_VALUE;
        window.localStorage.setItem('acdQueues',JSON.stringify(value));
      }
      return null
    })

    dispatch({
      type: actionTypes.settings.SET_DEFAULT_QUEUE_VALUE
    });
  }
}

const setForwardMedicom = (enabled = true) => {
  let currentLocalStorage = JSON.parse(window.localStorage.getItem('crm-config'));
  let medicomData = currentLocalStorage['medicom'];
  let data = {
    ...currentLocalStorage,
    medicom: {
      ...medicomData,
      forwardAll: enabled
    }
  }
  window.localStorage.setItem('crm-config', JSON.stringify(data));

  return {
    type: actionTypes.settings.FORWARD_CALLS_TO_MEDICOM,
    payload: enabled
  };
}

export default {
  setPrimaryPhone,
  setAutoAnswer,
  setCallForwarding,
  setDialPrefix,
  setDndStatus,
  fetchUserStatusSettings,
  setApiConnectionSeq,
  setACDConnection,
  setACDTool,
  setCRMConnector,
  setDefaultValueQueueValue,
  setForwardMedicom
};
