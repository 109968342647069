import { actionTypes } from '../constants/actionTypes';

const INITIAL_STATE = {
  primaryPhone: {},
  autoAnswer: {},
  dialPrefix: {},
  callForwarding: null,
  dnd: {},
  hot_desk: {},
  seq: 0,
  acd_tool: {},
  crm_connector: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.settings.SET_PRIMARY_PHONE:
      return {
        ...state,
        primaryPhone: action.payload
      };
    case actionTypes.settings.SET_AUTO_ANSWER:
      return {
        ...state,
        autoAnswer: action.payload
      };
    case actionTypes.settings.SET_DIAL_PREFIX:
      return {
        ...state,
        dialPrefix: action.payload
      };
    case actionTypes.settings.FETCH_USERSETTINGS_STATUS:
      return {
        ...state,
        callForwarding: action.payload.call_forward,
        dnd: action.payload.dnd,
        hot_desk: action.payload.hot_desk
      };
    case actionTypes.settings.UPDATE_USERSETTINGS_STATUS:
      return {
        ...state,
        callForwarding: action.payload.call_forward,
        dnd: action.payload.dnd,
        hot_desk: action.payload.hot_desk
      };
    case actionTypes.settings.SET_API_CONNECTION_SEQ:
      return {
        ...state,
        seq: action.payload.seq
      };
    case actionTypes.settings.SET_ACD_TOOL:
      return {
        ...state,
        acd_tool: action.payload
      };
    case actionTypes.settings.SET_CRM_CONNECTOR:
      return {
        ...state,
        crm_connector: action.payload
      };
    case actionTypes.settings.FORWARD_CALLS_TO_MEDICOM:
      return {
        ...state,
        forwardMedicom: action.payload
      };
    default:
      return state;
  }
};
