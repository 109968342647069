import { actionTypes } from '../constants/actionTypes';

export default (state = [], action) => {
  switch (action.type) {
    case actionTypes.user.FETCH_USER:
      return action.payload;
    case actionTypes.user.FETCH_USER_STATUS:
      return action.payload;
    default:
      return state;
  }
};
