import {actionTypes} from '../constants/actionTypes';
import CryptoService from '../services/cryptoService';
import { PROXY_SERVER } from '../constants/mainConst';

const fetchSalesforce = () => {
    const connectorData = window.localStorage.getItem('crm-config') ? JSON.parse(window.localStorage.getItem('crm-config')) : false;
    let salesforce = connectorData ? connectorData['salesforce'] : { enabled: false };

    if (salesforce.enabled && !salesforce.serverURL) {
        salesforce = {
            enabled: false
        }
    }

    return {
        type: actionTypes.connectors.FETCH_SALESFORCE,
        payload: salesforce
    };
};

const setSalesforceConnection = (enabled = false) => {
    let currentLocalStorage = window.localStorage.getItem('crm-config') ? JSON.parse(window.localStorage.getItem('crm-config')) : '';


    let data = {
        ...currentLocalStorage,
        salesforce: {
            ...currentLocalStorage.salesforce,
            enabled
        }
    }
    window.localStorage.setItem('crm-config', JSON.stringify(data));

    return {
        type: actionTypes.connectors.SET_SALESFORCE_ENABLED,
        payload: enabled
    };
};

const setSalesforceSettings = (
    serverURL = null,
    username = null,
    password = null,
    consumerKey = null,
    consumerSecret = null,
    securityToken = null
) => {

    const normalizeServerURL = !(/(https:\/\/[^s]+)/).test(serverURL) ? `https://${serverURL}` : serverURL;

    const encryptedString = value => {
        // eslint-disable-next-line no-unused-expressions
        return value && CryptoService.encryptString(value);
    };

    let currentLocalStorage = JSON.parse(window.localStorage.getItem('crm-config'));
    let data = {
        ...currentLocalStorage,
        salesforce: {
            enabled: true,
            serverURL: normalizeServerURL,
            username,
            password: encryptedString(password),
            consumerKey: encryptedString(consumerKey),
            consumerSecret: encryptedString(consumerSecret),
            securityToken: encryptedString(securityToken)
        }
    }
    window.localStorage.setItem('crm-config', JSON.stringify(data));

    return {
        type: actionTypes.connectors.SET_SALESFORCE_SETTING,
        payload: {
            enabled: true,
            serverURL: normalizeServerURL,
            username,
            password: encryptedString(password),
            consumerKey: encryptedString(consumerKey),
            consumerSecret: encryptedString(consumerSecret),
            securityToken: encryptedString(securityToken)
        }
    };
};

const clearSalesforceSettings = () => {
    let currentLocalStorage = JSON.parse(window.localStorage.getItem('crm-config'));
    let data = {
        ...currentLocalStorage,
        salesforce: {enabled: true}
    }
    window.localStorage.setItem('crm-config', JSON.stringify(data));

    return {
        type: actionTypes.connectors.SET_SALESFORCE_SETTING,
        payload: {
            enabled: true
        }
    };
}

// connector for Medicom
const fetchMedicom = () => {
    const connectorData = window.localStorage.getItem('crm-config') ? JSON.parse(window.localStorage.getItem('crm-config')) : false;
    let medicom = connectorData ? connectorData['medicom'] : {};

    if (medicom.enabled && !medicom.gpCrmUserId) {
        medicom = {
            enabled: false,
            serverURL: PROXY_SERVER
        }
    }

    return {
        type: actionTypes.connectors.FETCH_MEDICOM,
        payload: medicom
    };
};

const setMedicomConnection = (enabled = false) => {
    let currentLocalStorage = window.localStorage.getItem('crm-config') ? JSON.parse(window.localStorage.getItem('crm-config')) : '';

    let data = {
        ...currentLocalStorage,
        medicom: {
            ...currentLocalStorage.medicom,
            enabled
        }
    }
    window.localStorage.setItem('crm-config', JSON.stringify(data));

    return {
        type: actionTypes.connectors.SET_MEDICOM_ENABLED,
        payload: enabled
    };
};

const setMedicomSettings = (
    serverURL = null,
    gpCrmUserId = null
) => {

    const normalizeServerURL = !(/(https:\/\/[^s]+)/).test(serverURL) ? `https://${serverURL}` : serverURL;

    let currentLocalStorage = JSON.parse(window.localStorage.getItem('crm-config'));
    let data = {
        ...currentLocalStorage,
        medicom: {
            enabled: true,
            serverURL: normalizeServerURL,
            gpCrmUserId
        }
    }
    window.localStorage.setItem('crm-config', JSON.stringify(data));

    return {
        type: actionTypes.connectors.SET_MEDICOM_SETTING,
        payload: {
            enabled: true,
            serverURL: normalizeServerURL,
            gpCrmUserId
        }
    };
};

const clearMedicomSettings = () => {
    let currentLocalStorage = JSON.parse(window.localStorage.getItem('crm-config'));
    let data = {
        ...currentLocalStorage,
        medicom: {
            enabled: true,
            serverURL: PROXY_SERVER
        }
    }
    window.localStorage.setItem('crm-config', JSON.stringify(data));

    return {
        type: actionTypes.connectors.SET_MEDICOM_SETTING,
        payload: {
            enabled: true,
            serverURL: PROXY_SERVER
        }
    };
}

// customize connectors
const saveConnector = data => {
    const url = normalizeURL(data);
    let currentLocalStorage = window.localStorage.getItem('crm-config') ? JSON.parse(window.localStorage.getItem('crm-config')) : false;
    const templates = currentLocalStorage ? currentLocalStorage['templates'] : [];
    const newData = {
        key: data.templateName,
        value: url,
        enabled: true
    };
    templates.push(newData);

    let newLocalStorage = {
        ...currentLocalStorage,
        templates
    }

    window.localStorage.setItem('crm-config', JSON.stringify(newLocalStorage));

    return {
        type: actionTypes.connectors.SAVE_CONNECTOR,
        payload: newData
    };
};

const updateConnector = data => {
    const url = normalizeURL(data);
    let currentLocalStorage = window.localStorage.getItem('crm-config') ? JSON.parse(window.localStorage.getItem('crm-config')) : false;
    const connectors = currentLocalStorage ? currentLocalStorage['templates'] : [];
    const templates = connectors.map(item => {
        if (item.key === data.templateName) {
            item.value = url;
        }
        return item;
    });

    let newLocalStorage = {
        ...currentLocalStorage,
        templates
    }

    window.localStorage.setItem('crm-config', JSON.stringify(newLocalStorage));

    return {
        type: actionTypes.connectors.UPDATE_CONNECTOR,
        payload: templates
    };
};

const updateEnableConnector = (connector, enabled = true) => {
    const { key } = connector;
    let currentLocalStorage = window.localStorage.getItem('crm-config') ? JSON.parse(window.localStorage.getItem('crm-config')) : false;
    const connectors = currentLocalStorage ? currentLocalStorage['templates'] : [];
    const templates = connectors.map(item => {
        if (item.key === key) {
            item.enabled = enabled;
        }
        return item;
    });

    let newLocalStorage = {
        ...currentLocalStorage,
        templates
    }

    window.localStorage.setItem('crm-config', JSON.stringify(newLocalStorage));

    return {
        type: actionTypes.connectors.UPDATE_ENABLE_CONNECTOR,
        payload: templates
    };
};

const removeConnector = key => {
    let currentLocalStorage = window.localStorage.getItem('crm-config') ? JSON.parse(window.localStorage.getItem('crm-config')) : false;
    const templatesCurrent = currentLocalStorage ? currentLocalStorage['templates'] : [];
    const templates = templatesCurrent.filter(item => item.key !== key);

    let newLocalStorage = {
        ...currentLocalStorage,
        templates
    }

    window.localStorage.setItem('crm-config', JSON.stringify(newLocalStorage));

    return {
        type: actionTypes.connectors.REMOVE_CONNECTOR,
        payload: templates
    };
}

function normalizeURL(data) {
    let { serverURL } = data;
    serverURL = !/(https:\/\/[^s]+)/.test(serverURL)
        ? `https://${serverURL}`
        : serverURL;

    const params = data.fields
        .filter(item => item.key && item.value)
        .map(item => `${item.key}=${item.value}`)
        .join('&');

    return params ? `${serverURL}?${params}` : `${serverURL}`;
}

export default {
    fetchSalesforce,
    setSalesforceConnection,
    setSalesforceSettings,
    clearSalesforceSettings,
    fetchMedicom,
    setMedicomConnection,
    setMedicomSettings,
    clearMedicomSettings,
    saveConnector,
    updateConnector,
    updateEnableConnector,
    removeConnector
};
