import React from 'react';
import { Route, Switch } from 'react-router';
import pageRoutes from '../../constants/pageRoutes';
import Sidebar from './Sidebar';
import Logout from './Logout';
import Content from './Content';

class PageLayout extends React.Component {
    render() {
        return (
            <div id="content" className="ui text container">
                <div className="ui header" />
                <div className="ui stackable two column grid container">
                    <div className="row"><Logout/></div>
                    <div className="row"><Sidebar/></div>
                </div>
                <Switch>
                    {pageRoutes.map((route, i) => (
                        <Route
                            key={i}
                            exact={route.exact}
                            path={route.path}
                            name={route.name}
                        >
                            <Content>
                                {<route.component {...route} identifier={i}/>}
                            </Content>
                        </Route>
                    ))}
                </Switch>
            </div>
        );
    }
}

export default PageLayout;
