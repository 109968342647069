import { actionTypes } from '../constants/actionTypes';

export default (state = [], action) => {
  switch (action.type) {
    case actionTypes.phones.FETCH_PHONES:
      return action.payload;
    case actionTypes.phones.FETCH_PHONE_BY_ID:
      return [...state, action.payload];
    default:
      return state;
  }
};
