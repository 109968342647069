import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sendNotification } from "../../helpers/notificationHelper";
import settingsActions from '../../actions/settingsActions';

class SettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      icon4Scroll: "down",
      errorCallForwardNumber: false
    };
  }

  componentDidMount() {
    if (this.props.signedIn) {
      this.props.settingsActions.fetchUserStatusSettings();
    }
    window.addEventListener("scroll", this.onScrolling);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScrolling);
  }

  onScrolling = () => {
    let currentIcon = window.pageYOffset <= (window.innerHeight - 10) ? "down" : "up";
    this.setState({
          icon4Scroll: currentIcon
        })
  };

  onAutoAnswerToggleChange = checked => {
    this.props.settingsActions.setAutoAnswer(checked);
  };

  onMedicomToggleChange = checked => {
    console.log('onMedicomToggleChange', checked);
    this.props.settingsActions.setForwardMedicom(checked);
  }

  onCallForwardSubmit = (e, cfActive) => {
    e.preventDefault();
    let currentCallForwardNumber = e.target.cfnumber.value;

    if (currentCallForwardNumber.match(/^[0-9+)(*_\-\s]*$/)){
      this.props.settingsActions.setCallForwarding(
        cfActive ? currentCallForwardNumber : null
      );
      this.setState({ errorCallForwardNumber: false })
    } else {
      this.setState({ errorCallForwardNumber: true })
    }
  };

  onDndToggleChange = checked => {
    this.props.settingsActions.setDndStatus(checked);
  };

  onDialPrefixSubmit = (e, dpActive) => {
    e.preventDefault();

    let currentDialPrefix = e.target.dialprefixnumber.value;

    if (currentDialPrefix.match(/[0-9]{1,10}/)) {
      dpActive
        ? this.props.settingsActions.setDialPrefix(
        currentDialPrefix
        )
        : this.props.settingsActions.setDialPrefix();
    }
  };

  enabledAutoAnswer = () => {
    let disabled = true;
    if (this.props.primaryPhoneName){
      disabled = false
    } else {
      let arrayPhones = this.props.phones && this.props.phones.filter(phone => phone.personal && phone);
      if (arrayPhones && arrayPhones.length === 1){
        disabled = !!(arrayPhones[0].type !== 'hot-desk' && this.props.hot_desk);
      } else if (arrayPhones && arrayPhones.length === 2 && arrayPhones.filter(phone => phone.type === 'hot-desk').length === 1 && !this.props.hot_desk) {
        disabled = false
      }
    }
    if (disabled && !!this.props.autoAnswer) {
      this.props.settingsActions.setAutoAnswer(false);
      sendNotification(
          'Auto-answer disabled. Please choose the primary phone on Phones Page'
      );
    }
    return disabled
  }

  render() {
    const currentIcon4scroll = this.state.icon4Scroll;
    const showErrorCallForwardNumber = this.state.errorCallForwardNumber;
    const stateConnectorMedicom = !!this.props.connectMedicom.enabled;
    const cfActive = !this.props.callForwarding;
    const dpActive = !this.props.dialPrefixNumber;
    const enabledAutoAnswer = this.enabledAutoAnswer();

    return (
      <div>
        <div className="ui padded basic segment">
          <h2 className="ui header">
            <i className="cog icon" />
            <div className="content">Settings</div>
          </h2>
        </div>
        {/******************************************
           LANGUAGES
           *******************************************/}
        <div className="ui padded basic segment">
          <h3 className="ui header">
            <div className="content">Language</div>
          </h3>
        </div>
        <div className="ui section divider" />
        {/******************************************
           DND
           *******************************************/}
        <div className="ui padded basic segment">
          <div className="ui toggle checkbox">
            <input
              type="checkbox"
              name="dnd"
              checked={!!this.props.dnd}
              onChange={e => this.onDndToggleChange(e.target.checked)}
            />
            <label>
              <h3 className="ui header">
                <div className="content">Do Not Disturb (DND)</div>
              </h3>
            </label>
          </div>
        </div>
        <div className="ui section divider" />
        {/******************************************
           AUTO ANSWER
           *******************************************/}
        <div className="ui padded basic segment">
          <div className={`ui toggle checkbox`}>
            <input
              type="checkbox"
              name="autoanswer"
              checked={!!this.props.autoAnswer}
              disabled={enabledAutoAnswer}
              onChange={e => this.onAutoAnswerToggleChange(e.target.checked)}
            />
            <label>
              <h3 className="ui header">
                <div className="content">
                  Auto Answer{' '}
                  <sup
                    className="ui icon"
                    data-tooltip="If a primary phone is selected, it will be used with Auto Answer"
                    data-variation="basic"
                  >
                    <i className="question circle outline icon" />
                  </sup>
                </div>
              </h3>
            </label>
          </div>
          <div className="ui hidden divider" />
          <div className="description">
            Current primary phone:{' '}
            <span className="ui small header">
              {this.props.primaryPhoneName
                ? this.props.primaryPhoneName.length > 63
                  ? `${this.props.primaryPhoneName.toString().substr(0, 60)}...`
                  : this.props.primaryPhoneName
                : 'not selected'}
            </span>
          </div>
        </div>
        <div className="ui section divider" />
        {/******************************************
           CALL FORWARDING
           *******************************************/}
        <div className="ui padded basic segment">
          <h3 className="ui header">
            <div className="content">
              Call Forwarding{' '}
              <sup
                className="ui icon"
                data-tooltip="When the number is set, call forwarding is enabled. You have to disable call forwarding to be able to change the number"
                data-variation="basic"
              >
                <i className="question circle outline icon" />
              </sup>
            </div>
          </h3>
          <div className="ui hidden divider" />
          <div className="description">
            <form
              className="ui form"
              onSubmit={e => this.onCallForwardSubmit(e, cfActive)}
            >
              <div className="field">
                <label>Number to forward to:</label>
                <input
                  type="text"
                  name="cfnumber"
                  placeholder=""
                  defaultValue={
                    this.props.callForwarding ? this.props.callForwarding : ''
                  }
                  disabled={!!this.props.callForwarding}
                />
                {showErrorCallForwardNumber && <span style={{ 'color': 'red' }}>wrong value</span>}
              </div>
              <button
                className={`ui ${
                  this.props.callForwarding ? '' : 'primary'
                } button `}
                type="submit"
              >
                {this.props.callForwarding ? 'Disable' : 'Set Number'}
              </button>
            </form>
          </div>
        </div>
        <div className="ui section divider" />
        {/******************************************
           DIAL PREFIX
           *******************************************/}
        <div className="ui padded basic segment">
          <h3 className="ui header">
            <div className="content">
              Dial Prefix{' '}
              <sup
                className="ui icon"
                data-tooltip="If set then prefix will be used with click to dial. Use only if your PBX configuration needs that"
                data-variation="basic"
              >
                <i className="question circle outline icon" />
              </sup>
            </div>
          </h3>
          <div className="ui hidden divider" />
          <div className="description">
            <form
              className="ui form"
              onSubmit={e => this.onDialPrefixSubmit(e, dpActive)}
            >
              <div className="field">
                <label>Dial Prefix:</label>
                <input
                  type="number"
                  min={0}
                  max={10000}
                  name="dialprefixnumber"
                  placeholder=""
                  defaultValue={
                    this.props.dialPrefixNumber !== undefined
                      ? this.props.dialPrefixNumber
                      : ''
                  }
                  disabled={!!this.props.dialPrefixNumber}
                />
              </div>
              <button
                className={`ui ${
                  this.props.dialPrefixNumber ? '' : 'primary'
                } button `}
                type="submit"
              >
                {this.props.dialPrefixNumber ? 'Disable' : 'Set Number'}
              </button>
            </form>
          </div>
        </div>
        {/******************************************
         FORWARDING ALL INCOMING CALLS TO MEDICOM
         *******************************************/}
        {stateConnectorMedicom && (
            <div className='ui padded basic segment'>
              <div className='ui toggle checkbox'>
                <input
                    type='checkbox'
                    name='forwardToMedicom'
                    checked={this.props.forwardMedicom}
                    onChange={e => this.onMedicomToggleChange(e.target.checked)}
                />
                <label>
                  <h3 className='ui header'>
                    <div className='content'>
                      Forward all incoming calls to Medicom
                    </div>
                  </h3>
                </label>
              </div>
            </div>)}
        <div aria-label="arrow down">
          <button
            className="circular ui icon disabled button"
            style={{position: "fixed", bottom: "20px", right: "20px", display: "block", background: "#c0c0c0"}}
          >
            <i className={`angle ${currentIcon4scroll} icon`}/>
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    callForwarding: state.settings.callForwarding,
    dnd: state.settings.dnd,
    primaryPhoneName: state.settings.primaryPhone.name,
    dialPrefixNumber: state.settings.dialPrefix.number,
    autoAnswer: state.settings.autoAnswer.enabled,
    phones: state.user.phones,
    hot_desk: state.user.hot_desk,
    signedIn: state.authentication,
    forwardMedicom: state.settings.forwardMedicom,
    connectMedicom: state.connectors.medicom
  };
};

const mapDispatchToProps = dispatch => {
  return {
    settingsActions: bindActionCreators(settingsActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
